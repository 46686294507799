import { createRouter, createWebHistory } from 'vue-router';
import type { RouteRecordRaw } from 'vue-router';
import Dashboard from '../pages/Dashboard.vue';
import Homepage from '../pages/Homepage.vue';
import ListOrdersPage from '../pages/orders/ListOrdersPage.vue';
import CreateOrderPage from '../pages/orders/CreateOrderPage.vue';
import DetailOrderPage from '../pages/orders/DetailsOrderPage.vue';
import OrderInfoTab from '../pages/orders/details/OrderInfoTab.vue';
import OrderPackagesTab from '../pages/orders/details/OrderPackagesTab.vue';
import OrderPreviewTab from '../pages/orders/details/OrderPreviewTab.vue';
import OrderSupportTab from '../pages/orders/details/OrderSupportTab.vue';
import OrderJobPostingTab from '../pages/orders/details/OrderJobPostingTab.vue';
import PoolmirrorDetailPage from '../pages/poolmirror/PoolmirrorDetailPage.vue';
import ListCVPage from '../pages/cvanalyzer/ListCVPage.vue';

import AppsListPage from '../pages/promptsuite/AppsListPage.vue';
import AppDetailPage from '../pages/promptsuite/AppDetailPage.vue';

import ListAccountsPageVue from '@/pages/account/ListAccountsPage.vue';
import AccountDetailPage from '@/pages/account/AccountDetailPage.vue';
import SettingsOverviewPage from '@/pages/settings/SettingsOverviewPage.vue';

import ListCompaniesPage from '@/pages/company/ListCompaniesPage.vue';
import CompanyDetailPage from '@/pages/company/CompanyDetailPage.vue';

import Login from '../pages/Login.vue';
import ForgotPassword from '../pages/ForgotPassword.vue';
import ResetPassword from '../pages/ResetPassword.vue';
import { appName } from '@/config/app';

const routes = <Array<RouteRecordRaw>>[
  {
    path: '/',
    redirect: { name: 'home' },
  },
  {
    path: '/dashboard/home',
    component: Dashboard,
    // All pages should go as children to this component (they would inherit the layout of the Dashboard), and the login page is separate
    children: [
      {
        path: '/dashboard/home',
        name: 'home',
        component: Homepage,
      },
      {
        path: '/dashboard/orders/:company_name?',
        name: 'orders.list',
        component: ListOrdersPage,
      },
      {
        path: '/dashboard/orders/create',
        name: 'order.create',
        component: CreateOrderPage,
      },
      {
        path: '/dashboard/orders/detail/:id',
        name: 'order.detail',
        component: DetailOrderPage,
        children: [
          {
            path: '/dashboard/orders/detail/:id',
            redirect: { name: 'order.detail.info' },
          },
          {
            path: '/dashboard/orders/detail/:id/info',
            name: 'order.detail.info',
            component: OrderInfoTab,
          },
          {
            path: '/dashboard/orders/detail/:id/packages',
            name: 'order.detail.packages',
            component: OrderPackagesTab,
          },
          {
            path: '/dashboard/orders/detail/:id/preview',
            name: 'order.detail.preview',
            component: OrderPreviewTab,
          },
          {
            path: '/dashboard/orders/detail/:id/support',
            name: 'order.detail.support',
            component: OrderSupportTab,
          },
          {
            path: '/dashboard/orders/detail/:id/job_posting',
            name: 'order.detail.job_posting',
            component: OrderJobPostingTab,
          },
        ],
      },
      {
        path: '/dashboard/poolmirror/:id/detail',
        name: 'poolmirror.detail',
        component: PoolmirrorDetailPage,
      },
      {
        path: '/dashboard/cvanlayzer',
        name: 'cvanalyzer.list',
        component: ListCVPage,
      },
      {
        path: '/dashboard/promptsuite/apps',
        name: 'promptsuite.apps',
        component: AppsListPage,
      },
      {
        path: '/dashboard/promptsuite/apps/:id',
        name: 'promptsuite.app.detail',
        component: AppDetailPage,
      },
      {
        path: '/dashboard/settings',
        name: 'settings',
        component: SettingsOverviewPage,
      },
      {
        path: '/dashboard/settings/accounts',
        name: 'settings.accounts',
        component: ListAccountsPageVue,
      },
      {
        path: '/dashboard/settings/accounts/:id',
        name: 'settings.accounts.detail',
        component: AccountDetailPage,
      },
      {
        path: '/dashboard/settings/companies',
        name: 'settings.companies',
        component: ListCompaniesPage,
      },
      {
        path: '/dashboard/settings/companies/:id',
        name: 'settings.companies.detail',
        component: CompanyDetailPage,
      },
    ],
  },
  {
    name: 'login',
    path: '/login',
    component: Login,
    meta: { requiresAuth: false },
  },
  {
    name: 'forgotPassword',
    path: '/forgot_password',
    component: ForgotPassword,
    meta: { requiresAuth: false },
  },
  {
    name: 'resetPassword',
    path: '/reset_password',
    component: ResetPassword,
    meta: { requiresAuth: false },
  },
];

const addAuth = (el) => {
  if (!el.meta) {
    el.meta = {};
    if (el.meta.requiresAuth === undefined) {
      el.meta.requiresAuth = true;
    }
  }

  if (el.children && Array.isArray(el.children)) {
    el.children.forEach((child) => {
      addAuth(child);
    });
  }
  return el;
};

const authRoutes: Array<RouteRecordRaw> = Object.values(routes).map((el) => {
  return addAuth(el);
});

const router = createRouter({
  history: createWebHistory(),
  linkExactActiveClass: 'active router-link-active',
  routes: authRoutes,
});

router.beforeEach(async (to, from, next) => {
  document.title = to.meta.title ? `${to.meta.title} - ${appName}` : appName;

  next();
});

export default router;
