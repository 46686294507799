<template>
  <span :class="`status ${statusObj.color}`">
    {{ statusObj.name }}
  </span>
</template>
<script setup lang="ts">
import { computed } from 'vue';
import type { Order } from '@/types/types';
import translator from '@/locale/translator';

const props = defineProps<{ status?: Order['lead_order_status'] }>();
const statusObj = computed(() => {
  const defaultStatus = { name: translator.get(`order.status.${props.status}`), color: 'status-red', animated: true };
  switch (props.status) {
    case 'to_be_confirmed':
      return { ...defaultStatus, color: 'status-yellow' };
    case 'confirmed':
      return { ...defaultStatus, color: 'status-purple' };
    case 'running':
      return { ...defaultStatus, color: 'status-green' };
    case 'finished':
      return { ...defaultStatus, color: 'status-blue', animated: false };
    default:
      return { ...defaultStatus, name: 'Error' };
  }
});
</script>
<style>
.status {
  text-wrap: nowrap;
}
</style>
