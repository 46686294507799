<template>
  <div>
    <HeaderNavbar :title="rfObject?.firstname || 'Account'" :breadcrumbs="listBreadcrumbs"></HeaderNavbar>
    <div class="container-lead">
      <div class="card" v-if="rfObject">
        <div class="row g-0">
          <div class="col-12 col-md-3 border-end">
            <div class="card-body">
              <h4 class="subheader">{{ $t('account.settings') }}</h4>
              <div class="list-group list-group-transparent">
                <a
                  v-for="tab in availableTabs"
                  :key="tab.key"
                  href="#"
                  @click.prevent="currentTab = tab.key"
                  class="list-group-item list-group-item-action d-flex align-items-center"
                  :class="{ active: currentTab === tab.key }"
                  >{{ tab.label }}</a
                >
              </div>
              <h4 class="subheader mt-4">{{ $t('account.experience') }}</h4>
              <div class="list-group list-group-transparent">
                <a href="mailto:mario.doering@prospective.ch" class="list-group-item list-group-item-action">{{
                  $t('account.experience.give_feedback')
                }}</a>
              </div>
            </div>
          </div>
          <div class="col-12 col-md-9 d-flex flex-column">
            <div class="card-body" v-if="currentTab === 'profile'">
              <h2 class="mb-4">{{ $t('account.profile') }}</h2>
              <h3 class="card-title">{{ $t('account.profile.personal_details') }}</h3>
              <div class="row g-3">
                <div class="col-md-4">
                  <FormInput
                    v-model="rfObject.firstname"
                    :label="$t('account.profile.firstname')"
                    :errors="rfFunctions.errors('firstname')"
                  ></FormInput>
                </div>
                <div class="col-md-4">
                  <FormInput
                    v-model="rfObject.lastname"
                    :label="$t('account.profile.lastname')"
                    :errors="rfFunctions.errors('lastname')"
                  ></FormInput>
                </div>
              </div>
              <h3 class="card-title mt-4">{{ $t('account.profile.email') }}</h3>
              <p class="card-subtitle">{{ $t('account.profile.email_help') }}</p>

              <div class="row g-3">
                <div class="col-md-6">
                  <FormInput type="email" v-model="rfObject.email" :errors="rfFunctions.errors('email')"></FormInput>
                </div>
              </div>

              <h3 class="card-title mt-4">{{ $t('account.password.password') }}</h3>
              <p class="card-subtitle">
                {{ $t('account.password.explain') }}
              </p>
              <div>
                <a href="#" class="btn" @click.prevent="currentTab = 'password'">
                  {{ $t('account.password.set_new') }}</a
                >
              </div>
              <h3 class="card-title mt-4">{{ $t('account.profile.admin.title') }}</h3>
              <p class="card-subtitle">
                {{ $t('account.profile.admin_help') }}
              </p>
              <div>
                <FormInput
                  type="switch"
                  v-model="rfObject.is_admin"
                  class="form-switch-lg text-secondary ps-3"
                  :text="$t('account.profile.admin.text')"
                ></FormInput>
              </div>
            </div>
            <div class="card-body" v-if="currentTab === 'password'">
              <h2 class="mb-4">{{ $t('account.password') }}</h2>
              <h3 class="card-title mt-4">{{ $t('account.password.change') }}</h3>
              <p class="card-subtitle">
                {{ $t('account.password.change_help') }}
              </p>
              <div class="row">
                <div class="col-md-6">
                  <div class="mb-3">
                    <FormInput
                      type="password"
                      v-model="newPassword"
                      :label="$t('account.password.new_field')"
                      :errors="rfFunctions.errors('password')"
                    ></FormInput>
                  </div>

                  <div class="mb-3">
                    <FormInput
                      type="password"
                      v-model="newPasswordRepeat"
                      :label="$t('account.password.repeat_field')"
                      :errors="repeatPaswordErrors"
                    ></FormInput>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-footer bg-transparent mt-auto">
              <div class="btn-list justify-content-end">
                <LoadingButton @press="saveAndNavigate" class="btn btn-primary" :disabled="!canSave"
                  >{{ $t('account.profile.save') }} <IconCheck :size="17" class="ms-2"></IconCheck
                ></LoadingButton>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue';
import { FormInput, LoadingButton, PageHeader as LiPageHeader } from '@prospective/lithium';

import HeaderNavbar from '../header/HeaderNavbar.vue';
import { useRemoteForm, remoteFormOptions } from '@/form/remote';
import type { CustomerAccount } from '@/types/types';
import { IconCheck } from '@tabler/icons-vue';
import translator from '@/locale/translator';
import { useRoute, useRouter } from 'vue-router';
import { useToast } from 'vue-toastification';

/* --------------------------------- STATE -------------------------------- */

const router = useRouter();
const route = useRoute();
const newPassword = ref('');
const newPasswordRepeat = ref('');
const toast = useToast();

const rfOptions = remoteFormOptions('/v1/account/accounts/{id}');

const didComeFromResetPassword = computed(() => {
  return route.query.did_reset === '1';
});

rfOptions.createObject = () => {
  return {
    firstname: '',
    lastname: '',
    email: '',
    is_admin: false,
    is_active: true,
  };
};
rfOptions.prepareDataForSave = (data) => {
  if (newPassword.value.length > 0) {
    data.password = newPassword.value;
  }

  return data;
};
const { rfObject, rfFunctions } = useRemoteForm<CustomerAccount>(rfOptions);

const currentTab = ref(useRoute().query?.password ? 'password' : 'profile');
const availableTabs = [
  { key: 'profile', label: translator.get('account.profile') },
  { key: 'password', label: translator.get('account.password') },
];

/* -------------------------------- METHODS -------------------------------- */

const saveAndNavigate = async (stopLoading?: () => void) => {
  try {
    await rfFunctions.save(stopLoading);
    if (didComeFromResetPassword.value) {
      router.push({ name: 'home' });
    }
  } catch (error) {
    toast.error('Something went wrong. Please try again later');
  } finally {
    stopLoading?.();
  }
};
/* ------------------------------- COMPUTED ------------------------------ */
const listBreadcrumbs = computed(() => [
  { label: translator.get('nav.settings'), path: { name: 'settings' } },
  { label: translator.get('settings.accounts.title'), path: { name: 'settings.accounts' } },
  {
    label: translator.get('account.settings'),
    path: { name: 'settings.accounts.detail', params: { id: route.params.id } },
  },
]);
const canSave = computed(() => {
  if (newPassword.value.length > 0) {
    return newPassword.value === newPasswordRepeat.value;
  }

  return true;
});

const repeatPaswordErrors = computed(() => {
  // to not confuse the user unnecessarily, we only show the error
  // when both password have the same length
  if (newPassword.value.length > newPasswordRepeat.value.length) {
    return [];
  }

  if (newPassword.value !== newPasswordRepeat.value) {
    return [translator.get('account.password.passwords_need_to_match')];
  }

  return [];
});
</script>

<style lang="scss" scoped></style>
