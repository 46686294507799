<template>
  <ChatCard :channel="chatChannel" :key="chatChannel" class="mt-3 chat-card-block mb-4" id="chat" />
</template>
<script setup lang="ts">
import ChatCard from '@/components/chat/ChatCard.vue';
import { computed } from 'vue';

const props = defineProps<{ orderId: string }>();

const chatChannel = computed(() => `job:${props.orderId}.public`);
</script>
<style scoped>
.chat-card-block :deep(.chat) {
  max-height: 400px;
  overflow-y: auto;
}
</style>
