<template>
  <div class="card mt-3" id="channels">
    <div class="card-body">
      <p v-if="!products?.length" class="text-muted">{{ $t('orders.details.channels.empty') }}</p>
      <HorizontalList v-else>
        <div
          class="d-flex justify-content-center gap-2 border rounded py-2 px-3"
          v-for="channel in products"
          :key="channel.name"
        >
          <span
            class="avatar avatar-xs bg-transparent"
            :style="`background-image: url('${channel.image?.data?.proxy.url_thumbnail}')`"
          ></span>
          <span class="fw-bold">{{ channel.name }}</span>
        </div>
      </HorizontalList>
    </div>
  </div>
</template>
<script setup lang="ts">
import { request } from '@/api/client';
import HorizontalList from '@/components/generic/HorizontalList.vue';
import type { OrderProduct } from '@/types/types';
import { onMounted, ref } from 'vue';
import { IconInfoCircle } from '@tabler/icons-vue';

const products = ref<OrderProduct[]>();

const props = defineProps<{
  orderId: string;
}>();

onMounted(() => {
  request('GET', `/v1/account/order/${props.orderId}/products`, {
    resolve: 'image',
  }).then((res) => (products.value = res?.data));
});
</script>
