<template>
  <div v-if="featureStore.featurePromptsuite">
    <HeaderNavbar title="Promptsuite" :breadcrumbs="listBreadcrumbs">
      <template #right-side>
        <HeaderNavbarSearch v-model="searchQuery" />
      </template>
    </HeaderNavbar>
    <div class="container-lead">
      <div class="d-flex justify-content-center">
        <div
          class="card selectable-card p-3 d-flex align-items-center ms-2 animate__animated animate__fadeInDown animate__faster"
          :class="[currentFilter === category.filter ? 'selected' : '']"
          @click.prevent="currentFilter = category.filter"
          v-for="category in visibleFilterCategories"
          :key="category.filter ?? 'none'"
        >
          <div class="avatar" :class="getColorClasses(category.color)">
            <component :is="category.icon" :size="24" :stroke-width="1.2" />
          </div>
          <!-- <strong class="mt-2">{{ category.label }}</strong> -->
          <div class="subheader text-muted mt-2">{{ category.label }}</div>
        </div>
      </div>

      <li-page-header
        class="mt-3"
        :title="$t('promptsuite.apps.title', { filter: $t('promptsuite.apps.filter.' + (currentFilter || 'all')) })"
        :subtitle="$t('promptsuite.apps.subtitle')"
        :has-filters="false"
      >
      </li-page-header>

      <RemoteListView
        endpoint="/v1/account/promptsuite/apps"
        :search-query="searchQuery"
        class="mt-4"
        @did-fetch="handleListData"
        :parameters="requestParameters"
      >
        <template #empty>
          <div class="empty my-3">
            <div class="empty-icon">
              <IconMoodEmpty :size="24" :stroke-width="1.2" />
            </div>
            <p class="empty-title">No Apps found</p>
            <p class="empty-subtitle text-muted">
              Try adjusting your search or filters to find what you're looking for.
            </p>
          </div>
        </template>
        <template #loading>
          <div class="row">
            <div class="col-md-4" v-for="i in new Array(6)" :key="i">
              <div class="card mb-3">
                <div class="card-body p-4">
                  <h3 class="card-title mb-1">
                    <div class="placeholder col-7" />
                  </h3>
                  <div>
                    <div class="placeholder col-3" />
                    <br />
                    <div class="placeholder col-4" />
                    <br />
                    <div class="placeholder col-1" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>
        <template #collection="{ collection: apps }">
          <div class="row row-deck row-cards">
            <div class="col-xl-6 col-xxxl-4 col-md-6" v-for="(app, index) in apps" :key="app.id">
              <div
                class="animate__animated animate__fadeInUp animate__faster card app-card"
                :style="{ animationDelay: `${index * 0.1}s` }"
              >
                <RouterLink
                  :to="{ name: 'promptsuite.app.detail', params: { id: app.id } }"
                  custom
                  v-slot="{ navigate }"
                >
                  <div class="card-body p-4" @click="navigate">
                    <div class="d-flex">
                      <div>
                        <span class="avatar me-3 rounded" :class="getColorClasses(filterColorMap[app.category])">
                          <div v-if="app.icon" v-html="app.icon"></div>
                          <span v-else>{{ getAcronym(app.name) }}</span>
                        </span>
                      </div>
                      <div class="w-100">
                        <div>
                          <h2 class="fw-bold mb-2">
                            {{ appTranslator.get('promptapp:' + app.id + '.lead_title') }}
                          </h2>
                        </div>
                        <p class="text-muted mb-0">
                          {{ appTranslator.get('promptapp:' + app.id + '.lead_description') }}
                        </p>
                      </div>
                    </div>
                  </div>
                </RouterLink>
              </div>
            </div>
          </div>
        </template>
        <template #footer="{ page, changePage, startRecordIndex, endRecordIndex, filterCount, pageCount }">
          <FixedFooterSection>
            <p class="m-0">
              <strong>{{ $t('orders.pagination.page') }} {{ page }} {{ $t('common.of') }} {{ pageCount }}</strong>
              <span class="text-muted">
                ({{ startRecordIndex }}-{{ endRecordIndex }} {{ $t('common.of') }} {{ filterCount }}
                {{ $t('promptsuite.apps.title', { filter: '' }) }})
              </span>
            </p>
            <div class="d-flex gap-3">
              <button class="btn" :disabled="page <= 1" @click="changePage(page - 1)">
                {{ $t('orders.pagination.prev') }}
              </button>
              <button class="btn" :disabled="page >= pageCount" @click="changePage(page + 1)">
                {{ $t('orders.pagination.next') }}
              </button>
            </div>
          </FixedFooterSection>
        </template>
      </RemoteListView>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted, ref } from 'vue';
import { createTranslator, createTranslatorLanguage, PageHeader as LiPageHeader } from '@prospective/lithium';

import { useCustomerFeaturesStore } from '@/stores/customerFeatures';
import HeaderNavbar from '../header/HeaderNavbar.vue';
import HeaderNavbarSearch from '../header/HeaderNavbarSearch.vue';
import RemoteListView from '@/components/RemoteListView.vue';
import FixedFooterSection from '@/components/generic/FixedFooterSection.vue';
import { getAcronym } from '@/func/string/acronym';
import translator, { currentLanguage } from '@/locale/translator';
import {
  IconFileDescription,
  IconSocial,
  IconGridDots,
  IconMoodEmpty,
  IconUserCheck,
  IconDeviceDesktopSearch,
  IconUserSearch,
} from '@tabler/icons-vue';
import { fetchPromptSuiteAppCategories } from '@/api/promptsuite';

/* --------------------------------- STATE -------------------------------- */

const featureStore = useCustomerFeaturesStore();
const searchQuery = ref('');

const currentFilter = ref<string | null>(null);
const filterCategoryCounts = ref<{ [index: string]: number }>({});
const filterCategories = [
  {
    filter: null,
    label: translator.get('promptsuite.apps.filter.all'),
    color: '',
    icon: IconGridDots,
  },
  {
    filter: 'jobposting',
    label: translator.get('promptsuite.apps.filter.jobposting'),
    color: 'azure',
    icon: IconFileDescription,
  },
  {
    filter: 'socialmedia',
    label: translator.get('promptsuite.apps.filter.socialmedia'),
    color: 'orange',
    icon: IconSocial,
  },
  {
    filter: 'activesourcing',
    label: translator.get('promptsuite.apps.filter.activesourcing'),
    color: 'lime',
    icon: IconDeviceDesktopSearch,
  },
  {
    filter: 'interview',
    label: translator.get('promptsuite.apps.filter.interview'),
    color: 'red',
    icon: IconUserCheck,
  },
  {
    filter: 'personnelconsultant',
    label: translator.get('promptsuite.apps.filter.personnelconsultant'),
    color: 'yellow',
    icon: IconUserSearch,
  },
];

// build a quick filter to color map
const filterColorMap: { [index: string]: string } = {};
filterCategories.forEach((category) => {
  filterColorMap[category.filter ?? ''] = category.color;
});

let appTranslator = createTranslator(currentLanguage, [
  createTranslatorLanguage('de_CH', ['de_DE'], {}),
  createTranslatorLanguage('gsw_CH', ['de_CH'], {}),
  createTranslatorLanguage('de_DE', ['en_US'], {}),
  createTranslatorLanguage('en_US', [], {}),
]);
const listBreadcrumbs = [{ label: 'Promptsuite Apps', path: { name: 'promptsuite.apps' } }];
/* ------------------------------- COMPUTED ------------------------------ */

const requestParameters = computed(() => {
  return {
    category: currentFilter.value || '',
  };
});

const visibleFilterCategories = computed(() => {
  return filterCategories.filter((category) => {
    // we use the filterCategoryCounts to determine if a category should be visible
    // the all category is always visible
    if (!category.filter) {
      return true;
    }

    return filterCategoryCounts.value[category.filter] > 0;
  });
});

/* --------------------------------- METHODS -------------------------------- */

function handleListData(collection) {
  // merge all translations into one object
  let translations: { [index: string]: {} } = {};
  collection.forEach((app) => {
    for (const language in app.translations) {
      if (!translations[language]) {
        translations[language] = {};
      }

      translations[language] = {
        ...translations[language],
        ...app.translations[language],
      };
    }
  });

  // rebuild the translator with the new data
  appTranslator = createTranslator(currentLanguage, [
    createTranslatorLanguage('de_CH', ['de_DE'], translations.de_CH),
    createTranslatorLanguage('gsw_CH', ['de_CH'], translations.gsw_CH),
    createTranslatorLanguage('de_DE', ['en_US'], translations.de_DE),
    createTranslatorLanguage('en_US', [], translations.en_US),
  ]);
}

function getColorClasses(color: string) {
  return `bg-${color} text-${color}-fg`;
}

/* --------------------------------- LIFECYCLE -------------------------------- */

onMounted(() => {
  fetchPromptSuiteAppCategories().then((response) => {
    const categories = response.data;
    let map = {};
    for (const item of categories) {
      map[item.category] = item.count;
    }
    filterCategoryCounts.value = map;
  });
});
</script>

<style lang="scss" scoped>
.app-card {
  border: 3px solid rgba(0, 0, 0, 0);
  cursor: pointer;

  &:hover {
    // border: 3px solid #e5e7eb;
    border: 3px solid #216bc4;
  }
}

@media (min-width: 1620px) {
  .col-xxxl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
}
</style>
