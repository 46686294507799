<template>
  <div class="chat-attachment-image" v-if="isImage">
    <img :src="imageBase64" v-if="imageBase64" />
    <div class="chat-attachment-image-overlay">
      <a
        class="btn-download btn btn-icon btn-ghost-secondary text-white"
        :href="downloadUrl"
        @click.prevent="downloadContents()"
      >
        <i class="ti ti-m ti-download" />
      </a>
    </div>
  </div>
  <div v-else class="chat-attachment">
    <div class="attachment-type text-uppercase bg-red-lt">{{ nameParts.extension }}</div>
    <h5 class="attachment-name">{{ nameParts.name }}</h5>
    <a class="btn btn-download btn-icon btn-ghost-secondary" :href="downloadUrl" @click.prevent="downloadContents()">
      <i class="ti ti-m ti-download" />
    </a>
  </div>
</template>
<script setup lang="ts">
import type { IAttachment } from '@/types/chat.js';
import { computed, onMounted, ref } from 'vue';
import { request } from '@/api/client';

const props = defineProps<{ attachment: IAttachment }>();

const nameParts = computed(() => {
  const parts = props.attachment.filename.split('.');
  return { name: parts.slice(0, -1).join('.'), extension: parts.at(-1) };
});
const isImage = computed(() => props.attachment.filetype.includes('image'));
const downloadUrl = computed(() => `/v1/account/attachments/${props.attachment.id}/contents`);
const contentsBlob = ref();
const imageBase64 = ref();

onMounted(async () => {
  if (!isImage.value) return;

  await fetchContents();
  // Transform blob to base64 image
  const reader = new FileReader();
  reader.readAsDataURL(contentsBlob.value);
  reader.onloadend = () => {
    imageBase64.value = reader.result;
  };
});

const fetchContents = async () => {
  await request('GET', downloadUrl.value, undefined).then((res) => {
    contentsBlob.value = res;
  });
};

const downloadContents = async () => {
  if (!contentsBlob.value) await fetchContents();
  // For CSV,CSS or HTML files we have to transform the contents to BLOB
  if (!(contentsBlob.value instanceof Blob))
    contentsBlob.value = new Blob([contentsBlob.value], { type: props.attachment.filetype });

  const link = document.createElement('a');
  link.href = URL.createObjectURL(contentsBlob.value);
  link.download = props.attachment.filename;
  link.click();
  URL.revokeObjectURL(link.href);
};
</script>
<style scoped>
.chat-attachment,
.chat-attachment-image {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: max-content;
  max-width: 200px;
  max-height: 100px;
  padding: 4px;
  border: 1px solid lightgray;
  border-radius: 4px;
}

.chat-attachment-image img {
  width: 100px;
  height: auto;
  max-height: 100px;
  object-fit: contain;
}
.chat-attachment-image .chat-attachment-image-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
}

.chat-attachment h5 {
  padding: 0;
  margin: 0;
}
.chat-attachment .attachment-type {
  display: inline-flex;
  font-size: 0.7em;
  justify-content: center;
  align-items: center;
  width: min-content;
  height: 20px;
  border-radius: 4px;
  padding: 0 6px;
  margin-right: 0.5em;
}
.chat-attachment .attachment-name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.chat-attachment .btn-download {
  margin-left: 4px;
  padding: 4px;
}
</style>
