<template>
  <div class="card mt-3" id="previews" v-if="allowPreviews">
    <div class="card-body">
      <div class="row mb-3 align-items-center">
        <div class="col-6 d-flex justify-content-start">
          <button
            :class="`text-capitalize ms-1 btn ${previewType === key ? 'btn-primary' : 'btn-ghost-secondary'}`"
            v-for="(value, key) in previewTypes"
            :key="key"
            @click="previewType = key"
          >
            {{ value }}
          </button>
        </div>
      </div>
      <div class="previews-wrapper">
        <OrderBannerPreview :key="preview.key" v-for="preview in previewsToShow" :preview="preview" />
      </div>
      <div v-if="!previewsToShow.length" class="text-muted">{{ $t('orders.details.previews.empty') }}</div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { request } from '@/api/client';
import type { OrderPreview } from '@/types/types';
import { computed, onMounted, ref } from 'vue';
import OrderBannerPreview from '@/components/orders/OrderBannerPreview.vue';

const props = defineProps<{ orderId: string; allowPreviews: boolean }>();
const previews = ref<OrderPreview[]>();
const previewType = ref<string>('adform');

const previewsToShow = computed(() => (previews.value || []).filter((p) => p.key.includes(previewType.value)));
const allowPreviews = computed(() => props.allowPreviews);
const previewTypes = computed(() => {
  const allTypes = { adform: 'Display', instagram: 'Instagram', facebook: 'Facebook', google: 'Google' };
  if (!previews.value?.length) return {};
  for (const pKey in allTypes) {
    if (!previews.value.find((p) => p.key.includes(pKey))) delete allTypes[pKey];
  }
  // eslint-disable-next-line vue/no-side-effects-in-computed-properties
  if (!allTypes[previewType.value]) previewType.value = Object.keys(allTypes)[0];
  return allTypes;
});

onMounted(() => {
  request('GET', `/v1/account/order/${props.orderId}/campaign_previews`).then((res) => (previews.value = res?.data));
});
</script>
<style scoped>
.previews-wrapper {
  display: grid;
  grid-template-columns: repeat(auto-fit, 306px);
  gap: 12px;
}
</style>
