<template>
  <DropDown :align="'right'" width="600px">
    <template #trigger="{ toggleDropdown }">
      <a class="btn btn-white btn-icon h-100 d-flex align-items-center" @click.prevent="toggleDropdown">
        <IconFilter :size="22" />
      </a>
    </template>
    <template #dropdown>
      <div class="p-3 row">
        <div class="col-6">
          <label class="form-label">{{ $t('orders.filters.list_style') }}</label>
          <div class="row my-3">
            <div class="col-6">
              <div class="w-fit d-flex align-items-center gap-2" role="button" @click="internalLayout = 'list'">
                <ToggleButton class="btn-icon" :active="internalLayout === 'list'"><IconList /></ToggleButton>
                <span :class="{ 'fw-bold': true, 'text-muted': internalLayout !== 'list' }">{{
                  $t('orders.layout.list')
                }}</span>
              </div>
            </div>
            <div class="col-6">
              <div class="w-fit d-flex align-items-center gap-2" role="button" @click="internalLayout = 'grid'">
                <ToggleButton class="btn-icon" :active="internalLayout === 'grid'"><IconColumns3 /></ToggleButton>
                <span :class="{ 'fw-bold': true, 'text-muted': internalLayout !== 'grid' }">{{
                  $t('orders.layout.grid')
                }}</span>
              </div>
            </div>
          </div>
          <div>
            <label class="form-label">{{ $t('orders.filters.location') }}</label>
            <v-select
              class="mb-3"
              :placeholder="$t('orders.filters.location')"
              :modelValue="modelValue.workplace_location"
              :options="countries"
              @update:modelValue="(val) => changeInternal('workplace_location', val)"
            />
          </div>
          <div>
            <label class="form-label">{{ $t('orders.filters.language') }}</label>
            <v-select
              multiple
              :closeOnSelect="false"
              :searchable="false"
              class="mb-3"
              :modelValue="modelValue.language"
              :options="[
                { value: 'de', label: 'German' },
                { value: 'en', label: 'English' },
                { value: 'fr', label: 'French' },
                { value: 'it', label: 'Italian' },
              ]"
              :reduce="(o) => o.value"
              @update:modelValue="(val) => changeInternal('language', val)"
              @click.stop
            />
          </div>
        </div>
        <div class="col-6">
          <label class="form-label">{{ $t('orders.filters.time_range') }}</label>
          <div class="d-flex flex-wrap gap-2 my-3">
            <ToggleButton
              v-for="time in created_at_this"
              class="status"
              :active="created_after === time.value"
              :text="time.label"
              :key="time.value"
              @click="changeCreatedAfter(time.value)"
            />
          </div>
          <div class="hr text-muted my-3" />
          <div class="d-flex flex-wrap gap-2">
            <ToggleButton
              v-for="time in created_at_last"
              class="status"
              :active="created_after === time.value"
              :text="time.label"
              :key="time.value"
              @click="changeCreatedAfter(time.value)"
            />
          </div>
        </div>
      </div>
      <div class="d-flex justify-content-end align-items-center gap-3 p-3">
        <button class="btn btn-link p-0 text-danger" @click="emit('resetFilters')">
          {{ $t('orders.filters.reset_filters') }}
        </button>

        <button class="btn btn-dark" @click="calculateFilters">{{ $t('orders.filters.apply') }}</button>
      </div>
    </template>
  </DropDown>
</template>

<script setup lang="ts">
import { fetchAutocompleteFields } from '@/api/order';
import { DropDown } from '@prospective/lithium';
import { IconColumns3, IconFilter, IconList } from '@tabler/icons-vue';
import { ref, onMounted } from 'vue';
import { useToast } from 'vue-toastification';
import ToggleButton from '../common/ToggleButton.vue';
import { endOfDay, format, startOfDay, startOfMonth, startOfWeek, startOfYear, subDays } from 'date-fns';
import translator from '@/locale/translator';

const created_at_this = [
  { label: translator.get('orders.filters.everything'), value: '' },
  { label: translator.get('orders.filters.today'), value: 'today' },
  { label: translator.get('orders.filters.thisWeek'), value: 'this_week' },
  { label: translator.get('orders.filters.thisMonth'), value: 'this_month' },
  { label: translator.get('orders.filters.thisYear'), value: 'this_year' },
];
const created_at_last = [
  { label: translator.get('orders.filters.last7'), value: '7days' },
  { label: translator.get('orders.filters.last30'), value: '30days' },
  { label: translator.get('orders.filters.last90'), value: '90days' },
];

const props = defineProps<{ layout: 'list' | 'grid' }>();

const emit = defineEmits<{
  (event: 'changeLayout', value: 'list' | 'grid'): void;
  (event: 'submitFilters'): void;
  (event: 'resetFilters'): void;
}>();

const modelValue = defineModel<Record<string, any>>({ required: true });
const countries = ref<string[]>(['']);
const internalLayout = ref(props.layout);
const created_after = ref('');

onMounted(() => {
  fetchAutocompleteFields('workplace_location', { q: '' })
    .then((c) => (countries.value = c))
    .catch(() => useToast().error('Failed to catch available countries'));
});

/* --------------------------------- METHODS -------------------------------- */
const changeInternal = (key: keyof typeof modelValue.value, value: any) => {
  modelValue.value = { ...modelValue.value, [key]: value };
};
const changeCreatedAfter = (value: string) => {
  created_after.value = value;
  let dateFilter: Date;
  const today = new Date();

  if (value === 'today') dateFilter = startOfDay(today);
  else if (value === 'this_week') dateFilter = startOfWeek(today);
  else if (value === 'this_month') dateFilter = startOfMonth(today);
  else if (value === 'this_year') dateFilter = startOfYear(today);
  else if (value === '7days') dateFilter = subDays(today, 7);
  else if (value === '30days') dateFilter = subDays(today, 30);
  else if (value === '90days') dateFilter = subDays(today, 90);
  else dateFilter = endOfDay(today);

  changeInternal('created_at', format(dateFilter, 'yyyy-MM-dd 00:00:00'));
};
const calculateFilters = () => {
  emit('submitFilters');
  emit('changeLayout', internalLayout.value);
};
</script>
