<template>
  <!-- DISPLAY ORDER -->
  <div v-if="props.order">
    <div class="row row-deck">
      <OrderInfoCard
        class="col-12 col-lg-6 col-xxl-3 mb-3 mb-xxl-0"
        :title="$t('orders.details.impressions')"
        :info="order.stats_impressions_total || 0"
        :info_description="$t('common.impressions')"
        :graph_data="impresStatsData"
        :footer="improvementClickText"
      />
      <OrderInfoCard
        class="col-12 col-lg-6 col-xxl-3 mb-3 mb-xxl-0"
        :title="$t('orders.details.clicks')"
        :info="order.stats_clicks_total || 0"
        :info_description="$t('common.clicks')"
        :graph_data="clickStatsData"
        :footer="improvementImpresText"
      />
      <OrderInfoCard
        class="col-12 col-lg-6 col-xxl-3 mb-3 mb-xxl-0"
        :class="cpc_text.length > 10 ? 'info-muted' : ''"
        :title="$t('orders.details.cost_per_click')"
        :info="cpc_text"
        v-if="displayCost"
      />
      <OrderInfoCard
        class="col-12 col-lg-6 col-xxl-3 mb-3 mb-xxl-0"
        :title="$t('orders.details.total_cost')"
        :info="orderBudget"
        :show_graph="false"
        v-if="displayCost"
      />
    </div>

    <div class="row mt-4">
      <OrderBanner class="col-6 col-lg-5 h-100" :order="order!" :full-size="true" :show-actions="false" />
      <div class="col-6 col-lg-7 d-flex flex-column gap-y-3 ps-3 gap-3">
        <div class="d-flex align-items-center gap-2">
          <OrderCompanyLogo :company="order.company?.data" />
          <div>
            <h3 class="fw-bold m-0">{{ order.company_name }}</h3>
            <p class="m-0 text-muted">{{ companyAddress }}</p>
          </div>
        </div>
        <div class="d-flex align-items-center gap-2">
          <IconReport class="text-muted" />
          <span class="fw-bold text-nowrap">
            {{ formatDate(order?.startdate) }} - {{ formatDate(order?.enddate) }}
          </span>
        </div>
        <div class="d-flex align-items-center gap-2">
          <IconReport class="text-muted" />
          <span class="fw-bold text-nowrap"> {{ order.duration?.days }} {{ $t('orders.list.days') }} </span>
        </div>
        <div class="d-flex align-items-center gap-2">
          <IconMapPin class="text-muted" />
          <span class="fw-bold text-nowrap"> {{ order.workplace_location || $t('orders.list.worldwide') }} </span>
        </div>
        <div class="d-flex align-items-center gap-2">
          <IconCoins class="text-muted" />
          <span class="fw-bold text-nowrap"> {{ budgetText }}</span>
        </div>
        <div class="d-flex align-items-center gap-2">
          <IconPercentage class="text-muted" />
          <span class="fw-bold text-nowrap"> {{ workloadText }}</span>
        </div>
        <div class="d-flex align-items-center gap-2" v-if="creatorName">
          <IconUserCircle class="text-muted" />
          <span class="fw-bold text-nowrap"> {{ creatorName }}</span>
        </div>
      </div>
    </div>
    <div class="mt-4">
      <h3 class="mb-2">
        {{ $t('orders.wizard.remarks.title') }}
      </h3>
      <p v-if="order.comment">
        {{ order.comment }}
      </p>
      <p v-else class="text-muted">{{ $t('orders.details.remarks.empty') }}</p>
    </div>
  </div>
  <!-- PLACEHOLDER -->
  <div v-else class="py-4">
    <div class="pb-3 row row-deck">
      <div class="col-12 col-lg-6 col-xxl-3 mb-3 mb-xxl-0" v-for="i in [1, 2, 3, 4]" :key="i">
        <div class="card card-sm">
          <div class="card-body">
            <div class="placeholder placeholder-xs col-7 mb-3"></div>
            <div class="d-flex justify-content-between">
              <div class="placeholder placeholder-sm col-6" />
              <div class="placeholder placeholder-xs col-3 ms-auto" />
            </div>
            <div class="hr my-2" />
            <div class="placeholder placeholder-xs col-5"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="card card-sm">
      <div class="row g-0">
        <div class="col-3">
          <div class="ratio ratio-4x3 card-img-start placeholder"></div>
        </div>
        <div class="col">
          <div class="card-body">
            <div class="placeholder placeholder-xs col-5 mb-3"></div>
            <div class="placeholder placeholder col-8 mb-3"></div>
            <div class="placeholder placeholder-xs col-5"></div>
            <div class="col-8">
              <div class="placeholder placeholder-xs col-1 me-2" />
              <div class="placeholder placeholder-xs col-1 me-2" />
              <div class="placeholder placeholder-xs col-1" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import OrderBanner from '@/components/orders/order_elements/OrderBanner.vue';
import OrderCompanyLogo from '@/components/orders/order_elements/OrderCompanyLogo.vue';
import OrderPills from '@/components/orders/order_elements/OrderPills.vue';
import OrderInfoCard from '@/components/orders/OrderInfoCard.vue';
import { formatDate } from '@/filters/dateFormat';
import translator from '@/locale/translator';
import { IconCoins, IconMapPin, IconPercentage, IconReport, IconUserCircle } from '@tabler/icons-vue';
import { computed } from 'vue';
import { extractStats } from '@/func/statsCache';
import { formatCurrency, getCurrencySign } from '@/locale/format';
import { defaultCurrency, defaultLocale } from '@/config/app';
import type { Order } from '@/types/types';
import { useCustomerFeaturesStore } from '@/stores/customerFeatures';

const props = defineProps<{
  order: Order;
  orderId: string;
  allowPreviews: boolean;
}>();

const featureStore = useCustomerFeaturesStore();

/* -------------------------------- COMPUTED -------------------------------- */
const displayCost = computed(() => featureStore.featureOrderManagment?.display_cost);
const clickStatsData = computed(() => extractStats(props.order?.stats_cache || {}, 'clicks'));
const improvementClickText = computed(() =>
  calculateImprovement(clickStatsData.value.at(0)?.count, clickStatsData.value.at(1)?.count)
);
const impresStatsData = computed(() => extractStats(props.order?.stats_cache || {}, 'impressions'));
const improvementImpresText = computed(() =>
  calculateImprovement(impresStatsData.value.at(0)?.count, impresStatsData.value.at(1)?.count)
);
const cpc_text = computed(() => {
  if (
    props.order?.stats_cost_per_click &&
    featureStore.featureOrderManagment &&
    (featureStore.featureOrderManagment.cpc_t ?? 500) >= props.order.stats_cost_per_click
  )
    return formatCurrency(props.order.stats_cost_per_click, props.order.budget_currency);
  return translator.get('orders.details.cpc_waiting');
});

const orderBudget = computed(() => {
  const currency = props.order?.budget_currency ?? defaultCurrency;
  const budget = (props.order?.budget_gross_total || 0) / 100;
  return budget.toLocaleString(defaultLocale, {
    style: 'currency',
    currency,
  });
});

const companyAddress = computed(() => {
  const { company_street, company_zip, company_city, company_country } = props.order || {};
  if (!company_street) return;

  return `${company_street}, ${company_zip || ''} ${company_city || ''} ${company_country || ''}`.trim();
});

const workloadText = computed(() => {
  const { workload_min, workload_max } = props.order || {};
  if (workload_min === 100) return '100%';
  return `${workload_min || 0}% - ${workload_max || 100}%`;
});
const budgetText = computed(() => {
  const { budget_currency, budget_gross_total } = props.order || {};
  const sign = getCurrencySign(budget_currency || '');
  const budget = budget_gross_total ? budget_gross_total / 100 : 0;
  return `${sign} ${budget}`;
});
const creatorName = computed(() => {
  return props.order?.creator?.name || '';
});

/* --------------------------------- METHODS -------------------------------- */

const calculateImprovement = (today: number = 0, yesterday: number = 0) => {
  if ((!today && !yesterday) || today === yesterday)
    return `0% ${translator.get('orders.details.better_than_yesterday')}`;
  const increased = today > yesterday;
  const percentage = Math.ceil(100 * (increased ? (today - yesterday) / today : (yesterday - today) / yesterday));
  return `${percentage}% ${translator.get(
    increased ? 'orders.details.better_than_yesterday' : 'orders.details.worse_than_yesterday'
  )}`;
};
</script>
