<template>
  <button :class="{ 'btn toggleable': true, active: props.active }">
    <slot>{{ props.text }}</slot>
  </button>
</template>
<script setup lang="ts">
const props = defineProps<{ active: boolean; text?: string | number }>();
</script>
<style scoped>
.btn.toggleable {
  font-weight: normal;
  color: black;
  background-color: transparent;
}
.btn.toggleable.active {
  color: white;
  background-color: black;
}
.btn.btn-icon.toggleable {
  color: var(--tblr-muted);
  border: none;
  background-color: rgba(0, 0, 0, 0.1);
}
.btn.btn-icon.toggleable.active {
  color: white;
  border: none;
  background-color: black;
}
</style>
