<template>
  <div>
    <div class="py-4">
      <TransitionGroup name="slide-fade">
        <div v-if="currentWizardStep.key === 'url'" class="row d-flex justify-content-center">
          <div class="col-lg-6 col-xl-4 col-12" style="min-width: 466px">
            <div class="card card-md">
              <img src="./../../../assets/img/order-wizard/welcome.jpg" class="w-100" alt="" />
              <div class="card-body text-center py-3 p-sm-3 welcome-text">
                <div>
                  <h1 class="">{{ $t('orders.wizard.intro.heading') }}</h1>
                  <p class="text-muted">
                    {{ $t('orders.wizard.intro.subheading') }}
                  </p>
                </div>
              </div>
              <div class="hr-text hr-text-center hr-text-spaceless">{{ $t('orders.wizard.intro.job_posting') }}</div>
              <div class="card-body">
                <div class="mb-3">
                  <li-form-input
                    v-model="order.directlink"
                    :class="[isValidUrlEntered ? 'is-valid' : '']"
                    :label="$t('orders.wizard.steps.url')"
                    :is-required="true"
                    :errors="errors?.directlink"
                    placeholder="https://jobs.mycompany.com/job/1234"
                    :hint="$t('orders.wizard.intro.url_enter') + $t('orders.wizard.intro.url_help')"
                  >
                  </li-form-input>
                  <div class="mt-3">
                    <a
                      v-if="!openApplyLink"
                      href="#"
                      @click.prevent="openApplyLink = true"
                      style="text-decoration: underline"
                    >
                      {{ $t('orders.wizard.steps.apply_link') }}
                    </a>
                  </div>
                  <div v-if="openApplyLink" class="d-flex flex-row align-items-center">
                    <li-form-input
                      v-model="order.apply_url"
                      :class="[isValidApplyUrlEntered ? 'is-valid' : '']"
                      :label="$t('orders.wizard.steps.apply_url')"
                      :errors="errors?.apply_url"
                      :hint="$t('orders.wizard.intro.apply_url_help')"
                      class="mt-3"
                      placeholder="https://jobs.mycompany.com/job/1234/apply"
                      style="width: 95%"
                    >
                    </li-form-input>
                    <i
                      class="ti ti-x"
                      @click.prevent="openApplyLink = false"
                      style="cursor: pointer; font-size: 1.2rem; width: 10%"
                    ></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div v-if="currentWizardStep.key === 'analysis'">
          <div class="row d-flex justify-content-center">
            <div class="col-lg-12 col-xl-10 col-xxl-8 col-12">
              <div class="row row-deck row-cards">
                <div class="col-xl-6">
                  <div class="card card-md">
                    <div class="card-body">
                      <h1 class="">{{ $t('orders.wizard.analytics.title') }}</h1>
                      <p class="text-muted">
                        {{ $t('orders.wizard.analytics.subtitle') }}
                      </p>
                    </div>
                    <div class="hr-text hr-text-center hr-text-spaceless">
                      {{ $t('orders.wizard.analytics.campaign_duration') }}
                    </div>
                    <div class="card-body">
                      <div class="mb-3">
                        <FormDatePick
                          v-model:startdate="order.startdate"
                          v-model:enddate="order.enddate"
                          :default-campaign-length="computedMinLength ?? 0"
                        ></FormDatePick>
                      </div>
                    </div>
                    <div class="hr-text hr-text-center hr-text-spaceless">
                      {{ $t('orders.wizard.analytics.campaign_budget') }}
                    </div>
                    <div class="card-body">
                      <div class="mb-3">
                        <FormBudgetPick
                          v-if="order.budget_gross_total !== undefined"
                          v-model:budget="order.budget_gross_total"
                          :startdate="order.startdate"
                          :enddate="order.enddate"
                        ></FormBudgetPick>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-xl-6 d-none d-lg-block">
                  <div class="card card-md h-100 browser-card">
                    <div class="browser-container">
                      <div class="browser-container-top">
                        <span class="browser-container-dot"></span>
                        <span class="browser-container-dot"></span>
                        <span class="browser-container-dot"></span>
                        <span
                          class="status-indicator status-blue status-indicator-animated position-absolute"
                          style="right: 10px; top: 0px"
                        >
                          <span class="status-indicator-circle"></span>
                          <span class="status-indicator-circle"></span>
                          <span class="status-indicator-circle"></span>
                        </span>
                      </div>
                      <div class="browser-container-content">
                        <div class="scanning-continer">
                          <div
                            class="scanning-continer-inner"
                            :class="[scanningInProgress && previewImageLoaded ? 'active' : '']"
                          >
                            <div class="scanning-continer-line"></div>
                          </div>
                        </div>
                        <div>
                          <div v-if="!previewImageLoaded" class="card placeholder-glow m-3">
                            <div class="ratio ratio-21x9 card-img-top placeholder"></div>
                            <div class="card-body">
                              <div class="placeholder col-9 mb-3"></div>
                              <div class="placeholder placeholder-xs col-10"></div>
                              <div class="placeholder placeholder-xs col-11"></div>
                              <div class="mt-3">
                                <a
                                  href="#"
                                  tabindex="-1"
                                  class="btn btn-primary disabled placeholder col-4"
                                  aria-hidden="true"
                                ></a>
                              </div>
                            </div>
                          </div>
                          <div v-else>
                            <img :src="previewImageUrl" />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="timeline-container">
                      <WizardScanTimeline :timeline="scanTimeline" :is-done="scanTimelineIsDone" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div v-if="currentWizardStep.key === 'job_details'" class="row d-flex justify-content-center">
          <div class="col-lg-6 col-xl-4 col-12" style="min-width: 466px">
            <div class="card card-md">
              <div class="card-body">
                <h1 class="">{{ $t('orders.wizard.details.title') }}</h1>
                <p class="text-muted">{{ $t('orders.wizard.details.subtitle') }}</p>
              </div>
              <div class="hr-text hr-text-center hr-text-spaceless">Job</div>
              <div class="card-body">
                <div class="mb-3">
                  <li-form-input
                    v-model="order.title"
                    :label="$t('orders.wizard.details.job_title')"
                    :is-required="true"
                    :errors="errors?.title"
                    placeholder="Key Account Manager, Sales..."
                    hint="Provide your job title. Ideally without the workload '80-100%'. Try to keep it short and concise as space is limited."
                  >
                  </li-form-input>
                </div>
                <div class="mb-3">
                  <label class="form-label required">{{ $t('orders.wizard.details.company_name') }}</label>
                  <CompanySelector
                    v-model:company-name="order.company_name"
                    v-model:company-id="order.company_id"
                  ></CompanySelector>
                  <li-form-error-list :errors="errors?.company_name"></li-form-error-list>
                  <small class="text-muted">
                    {{ $t('orders.wizard.details.company_name_help') }}
                  </small>
                </div>
                <div>
                  <div class="row">
                    <div class="mb-3 col-6">
                      <label class="form-label required">{{ $t('orders.wizard.details.workload_min') }}</label>
                      <div class="input-group mb-2">
                        <input
                          v-model="order.workload_min"
                          type="number"
                          class="form-control"
                          autocomplete="off"
                          placeholder="80"
                        />
                        <span class="input-group-text"> % </span>
                      </div>
                      <li-form-error-list :errors="errors?.workload_min"></li-form-error-list>
                    </div>

                    <div class="mb-3 col-6">
                      <label class="form-label required">{{ $t('orders.wizard.details.workload_max') }}</label>
                      <div class="input-group mb-2">
                        <input
                          v-model="order.workload_max"
                          type="number"
                          class="form-control"
                          autocomplete="off"
                          placeholder="100"
                        />
                        <span class="input-group-text"> % </span>
                      </div>
                      <li-form-error-list :errors="errors?.workload_max"></li-form-error-list>
                    </div>

                    <li-form-input
                      v-model="order.workplace_location"
                      :label="$t('orders.wizard.details.location')"
                      :is-required="true"
                      class="col-12"
                      :errors="errors?.workplace_location"
                      placeholder="Zurich, Switzerland"
                      :hint="$t('orders.wizard.details.location_help')"
                    >
                    </li-form-input>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div v-if="currentWizardStep.key === 'plattforms'" class="row d-flex justify-content-center">
          <div class="col-lg-6 col-xl-4 col-12" style="min-width: 466px">
            <div class="card card-md">
              <div class="card-body">
                <h1 class="">{{ $t('orders.wizard.platforms.title') }}</h1>
                <p class="text-muted">
                  {{ $t('orders.wizard.platforms.subtitle') }}
                </p>
              </div>
              <div class="hr-text hr-text-center hr-text-spaceless">{{ $t('orders.wizard.platforms.platforms') }}</div>
              <div class="card-body">
                <WizardPlatformSelection v-model="order.preferred_plattforms" />
              </div>
            </div>
          </div>
        </div>

        <div v-if="currentWizardStep.key === 'products'" class="row d-flex justify-content-center">
          <div class="col-lg-12 col-xl-10 col-xxl-8 col-12" style="min-width: 466px">
            <WizardProductSelection v-model="order.selected_product_ids" @update:orderLimits="updateOrderLimits" />
          </div>
        </div>

        <div v-if="currentWizardStep.key === 'remarks'" class="row d-flex justify-content-center">
          <div class="col-lg-6 col-xl-4 col-12" style="min-width: 466px">
            <div class="card card-md">
              <img src="./../../../assets/img/order-wizard/remarks.jpg" class="w-100" alt="" />
              <div class="card-body text-center py-3 p-sm-3 welcome-text">
                <div>
                  <h1 class="">{{ $t('orders.wizard.remarks.title') }}</h1>
                  <p class="text-muted">
                    {{ $t('orders.wizard.remarks.subtitle') }}
                  </p>
                </div>
              </div>
              <div class="hr-text hr-text-center hr-text-spaceless">{{ $t('orders.wizard.remarks.title') }}</div>
              <div class="card-body">
                <div class="mb-3">
                  <li-form-input
                    v-model="order.comment"
                    type="textarea"
                    :label="$t('common.message')"
                    :is-required="false"
                    :errors="errors?.comment"
                    :placeholder="$t('orders.wizard.remarks.msg_placeholder')"
                  >
                  </li-form-input>
                </div>
              </div>
            </div>
          </div>
        </div>
        <WizardMediaSelection v-if="currentWizardStep.key === 'media'" />
      </TransitionGroup>
    </div>

    <FixedFooterSection>
      <div class="col-3 col-lg-2 col-xxl-1 d-flex flex-column">
        <p class="mb-2 fw-bold">
          {{ $t('common.step') }} {{ currentWizardStepSequence }} {{ $t('common.of') }} {{ wizardSteps.length }}
        </p>
        <div class="progress">
          <div class="progress-bar" :style="{ width: currentWizardProgress + '%' }" role="progressbar">
            <span class="visually-hidden">{{ currentWizardProgress }}% Complete</span>
          </div>
        </div>
      </div>
      <div class="d-flex gap-3">
        <div class="btn-list justify-content-end">
          <Transition name="fade">
            <button
              v-if="currentWizardStepSequence > 1"
              class="btn btn-white"
              @click.prevent="currentWizardStepSequence--"
            >
              <IconArrowLeft class="ms-1" :size="20" /> {{ $t('btn.back') }}
            </button>
          </Transition>
          <button class="btn btn-primary" :disabled="!canClickNext" @click.prevent="currentWizardStepSequence++">
            {{ $t('btn.continue') }} <IconArrowRight class="ms-1" :size="20" />
          </button>
        </div>
      </div>
    </FixedFooterSection>
  </div>
</template>

<script setup lang="ts">
import { computed, watch, ref, shallowRef, triggerRef, inject } from 'vue';
import type { Ref } from 'vue';
import {
  IconArrowRight,
  IconArrowLeft,
  IconCloudDownload,
  IconWifi,
  IconPhoto,
  IconMicroscope,
  IconCheck,
} from '@tabler/icons-vue';

import FormDatePick from './FormDatePick.vue';
import FormBudgetPick from './FormBudgetPick.vue';
import type { ScanTimelineItem } from './wizard_sections/WizardScanTimeline.vue';
import FixedFooterSection from '@/components/generic/FixedFooterSection.vue';
import CompanySelector from '@/components/company/CompanySelector.vue';
import {
  WizardMediaSelection,
  WizardProductSelection,
  WizardScanTimeline,
  WizardPlatformSelection,
} from './wizard_sections';

import { fetchUrlPreviewImage } from '@/api/media';
import { extractDataFromURL } from '@/api/order';

import type { Order, OrderLimits } from '@/types/types';

import { FormInput as LiFormInput, FormErrorList as LiFormErrorList } from '@prospective/lithium';
import { useCustomerFeaturesStore } from '@/stores/customerFeatures';
import type { OrderCreationData } from './orderCreation';

import { hasOrderValidBudgetAndDurationSettings } from './orderCreation';
import translator from '@/locale/translator';
import { isValidURL } from '@/func/string/url';

interface WizardSection {
  title: string;
  canGoNext?: () => boolean;
}

interface WizardStep {
  key: string;
  sequence: number;
  section: WizardSection;
}

const minDurationDays = ref<number>();

const featureStore = useCustomerFeaturesStore();

const emit = defineEmits(['exitWizard']);

const props = defineProps({
  errors: { type: Object, required: true },
  defaultCampaignLength: { type: Number, required: true },
});

const order = inject('order') as Ref<Order>;
const orderCreation = inject('orderCreation') as Ref<OrderCreationData>;
const openApplyLink = ref(false);

// props.order.directlink = 'https://migros-gruppe.jobs/de/unsere-unternehmen/job/genossenschaft-migros-aare/verkauferin-frischefood/db3d2c34-f0c9-46a8-b733-40181cc26e78';

/**
 * Navigation
 */
const availableWizardSteps: { [index: string]: WizardSection } = {
  url: {
    title: 'URL',
    canGoNext: () => {
      let isApplyOk = true;
      if (order.value.apply_url && order.value.apply_url.length > 0) {
        isApplyOk = isValidApplyUrlEntered.value;
      }
      return isValidUrlEntered.value && isApplyOk;
    },
  },
  plattforms: {
    title: 'Platforms',
  },
  products: {
    title: translator.get('orders.wizard.products.title'),
    canGoNext: () => {
      if (!order.value.selected_product_ids) {
        return false;
      }
      return order.value.selected_product_ids.length > 0;
    },
  },
  analysis: {
    title: translator.get('orders.wizard.analytics.title'),
    canGoNext: () => {
      return hasOrderValidBudgetAndDurationSettings(order.value, orderCreation.value);
    },
  },
  job_details: {
    title: translator.get('orders.wizard.details.title'),
    canGoNext: () => {
      return areJobDetailsEntered.value;
    },
  },
  remarks: {
    title: translator.get('orders.wizard.remarks.title'),
  },
  media: {
    title: translator.get('orders.wizard.media.title'),
  },
};

// build the steps
const currentWizardStepSequence = ref(1);
const wizardSteps: WizardStep[] = [];

function attachWizardStep(key: string): void {
  wizardSteps.push({
    key,
    sequence: wizardSteps.length + 1,
    section: availableWizardSteps[key],
  });
}

function updateOrderLimits(orderLimits: Partial<OrderLimits>) {
  order.value.budget_gross_total = orderLimits.minTotalBudget;
  minDurationDays.value = orderLimits.minDurationDays;
  order.value.startdate = orderLimits.calculatedStartDate ?? '';
  order.value.enddate = orderLimits.calculatedEndDate ?? '';
}

const computedMinLength = computed(() => {
  if (order.value.selected_product_ids) {
    return minDurationDays.value !== 0 ? minDurationDays.value : props.defaultCampaignLength;
  } else {
    return props.defaultCampaignLength;
  }
});
// we always have a URL step
attachWizardStep('url');

// if platform selection settings is allowed, we add the plattform step
if (featureStore.featureOrderManagment?.provider_selection_mode === 'provider') {
  attachWizardStep('plattforms');
} else if (featureStore.featureOrderManagment?.provider_selection_mode === 'product') {
  attachWizardStep('products');
}

attachWizardStep('analysis');
attachWizardStep('job_details');
attachWizardStep('media');
attachWizardStep('remarks');

// computed prop of the current progress
const currentWizardProgress = computed(() => {
  return (currentWizardStepSequence.value / wizardSteps.length) * 100;
});

const currentWizardStep = computed(() => {
  return wizardSteps[currentWizardStepSequence.value - 1] || {};
});

// computed prop to tell the next button if the current step is valid
const canClickNext = computed(() => {
  if (currentWizardStep.value?.section.canGoNext) {
    return currentWizardStep.value?.section.canGoNext();
  }

  return true;
});

const isValidUrlEntered = computed(() => isValidURL(order.value.directlink ?? ''));
const isValidApplyUrlEntered = computed(() => isValidURL(order.value.apply_url ?? ''));

// computed prop to determine if job details have been entered
const areJobDetailsEntered = computed<boolean>((): boolean => {
  return !!(
    order.value.title &&
    order.value.company_name &&
    order.value.workload_min &&
    order.value.workload_max &&
    order.value.workplace_location
  );
});

// watch the current wizard step as some actions depend on it
watch(currentWizardStepSequence, (newVal) => {
  if (newVal === 1) {
    // reset the preview image
    previewImageLoaded.value = false;
    previewImageUrl.value = undefined;
  }

  // if we enter the analysis step, start the analysis
  else if (currentWizardStep.value.key === 'analysis') {
    scanTimeline.value = [{ title: translator.get('orders.wizard.analytics.connecting'), icon: IconWifi }];

    setTimeout(() => {
      addScanTimelineItem(translator.get('orders.wizard.analytics.crawling'), IconCloudDownload);
    }, 1000);

    extractJobPostingData();
    fetchPostingPreview();
  }
  // After last step, switch to full form
  if (newVal > wizardSteps.length) emit('exitWizard');
});

const goToStepByKey = (key: string) => {
  const findStepIndex = wizardSteps.findIndex((wStep) => wStep.key === key);
  if (findStepIndex > -1) currentWizardStepSequence.value = findStepIndex + 1;
};
// We expose this, so that the OrderPage can change the step as well
defineExpose({ goToStepByKey });

/**
 * URL Image Preview
 */
const previewImageUrl: Ref<string | undefined> = ref();
const fetchPostingPreview = () => {
  if (isValidUrlEntered.value) {
    fetchUrlPreviewImage(order.value.directlink)
      .then((response) => {
        previewImageUrl.value = response.data['800x600'];
      })
      .catch((e) => console.error(e));
  }
};

// watch directlink for changes and debounce the fetchPostingPreview call
// watch(() => order.value.directlink, debounce(fetchPostingPreview, 600));

// watch the image URL so we can preload it and handle a loading state
const previewImageLoaded = ref(false);
watch(
  () => previewImageUrl.value,
  (newVal) => {
    if (!newVal) {
      return;
    }

    previewImageLoaded.value = false;
    let img = new Image();
    img.onload = () => (previewImageLoaded.value = true);
    img.src = newVal;

    if (scanTimeline.value.length > 1) {
      addScanTimelineItem('Rendering Publication', IconPhoto);
      addScanTimelineItem('Analysing Data', IconMicroscope);
    }
  }
);

/**
 * Scanning Timeline
 */
// the timeline is mostly fake and serves only the purpose of
// distracting the user while we run our AI bot over the job posting
// this takes up to 20 seconds..
const scanTimeline = shallowRef<ScanTimelineItem[]>([]);
const scanTimelineIsDone = shallowRef(false);

function addScanTimelineItem(title, icon) {
  scanTimeline.value = [...scanTimeline.value, { title, icon }];
  triggerRef(scanTimeline);
}

/**
 * JobPosting AI
 */
// request an analysis of the job posting
let didScanJobPosting = false;
let scanningInProgress = ref(false);
function extractJobPostingData() {
  // don't scan it twice..
  if (didScanJobPosting) {
    return;
  }

  scanningInProgress.value = true;

  extractDataFromURL(order.value.directlink)
    .then((response) => {
      const extractedData = response.data;

      scanningInProgress.value = false;

      if (extractedData === null || extractedData === undefined) {
        addScanTimelineItem('Done!', IconCheck);
        scanTimelineIsDone.value = true;
        return;
      }

      // try to assign fields
      if (extractedData.job_title) {
        order.value.title = extractedData.job_title;
      }

      if (extractedData.job_company) {
        order.value.company_name = extractedData.job_company;
      }

      if (extractedData.job_workplace) {
        order.value.workplace_location = extractedData.job_workplace;
      }

      if (parseInt(extractedData.job_workload_min) > 0) {
        order.value.workload_min = extractedData.job_workload_min;
      }

      if (parseInt(extractedData.job_workload_max) > 0) {
        order.value.workload_max = extractedData.job_workload_max;
      }

      addScanTimelineItem(`${translator.get('common.title')}: ${order.value.title}`, IconArrowRight);

      setTimeout(() => {
        addScanTimelineItem(`${translator.get('common.company')}: ${extractedData.job_company}`, IconArrowRight);
      }, 1000);

      setTimeout(() => {
        addScanTimelineItem(`${translator.get('common.workspace')}: ${extractedData.job_workplace}`, IconArrowRight);
      }, 2000);

      setTimeout(() => {
        addScanTimelineItem(`${translator.get('common.done')}!`, IconCheck);
        scanTimelineIsDone.value = true;
      }, 3000);
    })
    .catch(() => {
      // we don't let the user know about the error
      // we simply pretend that the scan was successful
      addScanTimelineItem(`${translator.get('common.done')}!`, IconCheck);
      scanningInProgress.value = false;
      scanTimelineIsDone.value = true;
    });
}
</script>

<style scoped>
.progress {
  background-color: rgb(227, 227, 227);
}
.welcome-text {
  margin-top: -80px;
}

.welcome-text div {
  border-radius: 10px;
  background-color: white;
  padding: 20px;
}

.browser-container-dot {
  height: 12px;
  width: 12px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
  margin-left: 5px;
}

.browser-container {
  border: 3px solid #f1f1f1;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}
.browser-card {
  background-color: #f1f1f1;
}
.browser-container-top {
  padding: 10px;
  background: #f1f1f1;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.browser-container-content {
  display: block;
  position: relative;
  overflow: auto;
  width: 100%;
}

.timeline-container {
  max-height: 200px;
  overflow-y: scroll;
  overflow-x: hidden;
  background: #f1f1f1;
}

.scanning-continer {
  display: block;
  z-index: 10;
  position: absolute;
  width: 100%;
  height: 100%;
  padding: 10px;
}

.scanning-continer-inner {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  overflow: hidden;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.scanning-continer-inner.active {
  opacity: 1;
}

@keyframes scannline {
  0% {
    margin-top: 0px;
  }
  50% {
    margin-top: 80%;
  }
  100% {
    margin-top: 0%;
  }
}

.scanning-continer-line {
  width: 100%;
  height: 3px;
  background-color: red;
  box-shadow: 0 0 30px rgba(255, 0, 0, 1);
  animation-name: scannline;
  animation-duration: 5s;
  animation-iteration-count: infinite;
}

.slide-fade-enter-active .card {
  transition: all 0.4s ease-out;
  position: absolute;
}

.slide-fade-leave-activ .card {
  position: absolute;
  opacity: 0;
}

.slide-fade-enter-from .card,
.slide-fade-leave-to .card {
  transform: translateX(50px);
  opacity: 0;
}
</style>
