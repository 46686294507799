<template>
  <div class="page antialiased">
    <div>
      <aside
        class="navbar navbar-lead navbar-vertical navbar-expand-md navbar-dark lead-navbar"
        :class="['navbar-apptype-' + appType?.toLowerCase()]"
      >
        <div class="container-fluid">
          <button class="navbar-toggler collapsed" type="button" @click="openNav = !openNav">
            <span class="navbar-toggler-icon"></span>
          </button>
          <h1 class="navbar-brand navbar-brand-autodark">
            <a href="/" class="text-center d-none d-md-block">
              <div v-if="appType == 'HRIQ'">
                <!-- <AnimatedPromptLogo></AnimatedPromptLogo> -->
                <img :src="defaultLogoPath" class="navbar-brand-image w-100 mt-3 mb-5" style="height: 6rem" />
              </div>
              <img v-else :src="defaultLogoTextPath" class="navbar-brand-image" style="height: 8rem" />
              <p style="margin-top: -20px">
                <span class="badge bg-blue" style="font-size: 0.6rem">v1.1</span>
              </p>
            </a>
            <a href="/" class="text-center d-md-none">
              <img
                :src="defaultLogoTextPath"
                class="navbar-brand-image"
                :style="{ transform: 'scale(' + (openNav ? 3 : 2) + ') translateY(' + (openNav ? 5 : -1) + 'px)' }"
              />
            </a>
          </h1>
          <div class="navbar-nav flex-row d-md-none">
            <div class="nav-item dropdown">
              <a
                href="#"
                class="nav-link d-flex lh-1 text-reset p-0"
                data-bs-toggle="dropdown"
                aria-label="Open user menu"
              >
                <UserAvatar class="avatar-sm"></UserAvatar>
              </a>
              <div class="dropdown-menu dropdown-menu-end dropdown-menu-arrow">
                <a href="#" class="dropdown-item">Status</a>
                <a href="./profile.html" class="dropdown-item">Profile</a>
                <a href="#" class="dropdown-item">Feedback</a>
                <div class="dropdown-divider"></div>
                <a href="./settings.html" class="dropdown-item">Settings</a>
                <a href="./sign-in.html" class="dropdown-item">Logout</a>
              </div>
            </div>
          </div>
          <div class="navbar-collapse position-relative" :class="{ collapse: !openNav }">
            <ul class="navbar-nav navbar-lead-main pt-lg-3">
              <template v-for="nav in navigationData" :key="nav.label">
                <router-link :to="nav.to" v-slot="{ href, navigate, isExactActive }" custom>
                  <li :class="isActiveClass($route.path, nav.to, isExactActive)" class="nav-item">
                    <a :href="href" @click="navigate" class="nav-link">
                      <span class="me-3">
                        <component :is="nav.icon"></component>
                      </span>
                      <span class="nav-link-title nav-text-size">{{ nav.label }}</span>
                    </a>
                  </li>
                  <!-- RENDER SUBMENU, USE CHILDREN WITH VUE 3 TELEPORT -->
                  <div :id="nav.teleportId" class="dropdown-menu show" />
                </router-link>
              </template>
            </ul>
            <div class="position-absolute w-100 d-none d-sm-block" style="bottom: 0">
              <div class="p-3">
                <div class="form-floating">
                  <select
                    class="form-select"
                    @change="setLanguage(($event.target as HTMLInputElement).value)"
                    v-model="currentLanguage"
                  >
                    <option v-for="(language, code) in availableLanguages" :key="code" :value="code">
                      {{ language.label }} - {{ language.country }}
                    </option>
                  </select>
                  <label for="floatingSelect">{{ $t('language') }}</label>
                </div>
              </div>

              <ul class="navbar-nav navbar-lead-main py-0 pb-2">
                <li class="nav-item mb-2">
                  <RouterLink class="nav-link" :to="{ name: 'settings' }">
                    <span class="me-3">
                      <component :is="IconSettings"></component>
                    </span>
                    <span class="nav-link-title nav-text-size">{{ $t('nav.settings') }}</span>
                  </RouterLink>
                </li>
                <li class="nav-item mb-2">
                  <RouterLink
                    class="nav-link"
                    :to="{ name: 'settings.accounts.detail', params: { id: userStore.currentUser?.id } }"
                  >
                    <span class="me-3">
                      <component :is="IconUserCircle"></component>
                    </span>
                    <span class="nav-link-title nav-text-size">{{ $t('nav.account') }}</span>
                  </RouterLink>
                </li>

                <li class="nav-item">
                  <div class="d-flex align-items-center" v-if="props.showProfile && userStore.currentUser">
                    <Avatar class="flex-shrink-0" :name="userStore.currentUser.email ?? '?'" :size="20" />
                    <div class="ms-2 d-none d-md-block">
                      <div>
                        <strong>{{ userStore.currentUser.customer?.data?.name }}</strong>
                      </div>
                      <div class="text-truncate text-muted">
                        <small class="">{{ userStore.currentUser.email }}</small>
                      </div>
                    </div>
                    <VDropdown placement="top-end" class="ms-auto">
                      <!-- This will be the popover reference (for the events and position) -->
                      <button class="btn btn-ghost-light btn-icon-menu"><IconDots :size="20" /></button>

                      <!-- This will be the content of the popover -->
                      <template #popper>
                        <ul class="list-group">
                          <button
                            class="list-group-item list-group-item-action d-flex align-items-center gap-1"
                            @click.prevent="logoutUser"
                          >
                            <IconLogout :size="14" />
                            <span>{{ $t('sign_out') }}</span>
                          </button>
                        </ul>
                      </template>
                    </VDropdown>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </aside>

      <div class="lead-wrapper">
        <div class="wrapper">
          <div class="page-wrapper">
            <router-view></router-view>
          </div>
        </div>
        <!-- <div style="height: 120px"></div> -->
        <!-- <Footer></Footer> -->
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, computed } from 'vue';
import UserAvatar from '../components/user/UserAvatar.vue';
import { getMainNavigationElements } from '../router/main_nav';
import { Avatar } from '@prospective/lithium';
import { availableLanguages, setLanguage, currentLanguage } from '@/locale/translator';
import translator from '@/locale/translator.js';

import { useCustomerFeaturesStore } from '@/stores/customerFeatures';

import type { MainNavElement } from '@/router/main_nav';
import {
  IconFileCertificate,
  IconLogout,
  IconBrain,
  IconBox,
  IconSettings,
  IconDots,
  IconUserCircle,
} from '@tabler/icons-vue';
import { appType, defaultLogoPath, defaultLogoTextPath } from '@/config/app';
import { destroySession } from '@/api/client';
import { useUserStore } from '@/stores/user';

const props = withDefaults(defineProps<{ showProfile?: boolean }>(), {
  showProfile: true,
});

// stores
const userStore = useUserStore();
const featureStore = useCustomerFeaturesStore();

// Manually trigger the navbar because the bootstrap version didn't work properly
const openNav = ref(true);
// in case this is a mobile screen, hide the navbar by default (the page has to be reloaded to read the screen size)
const width = window.innerWidth > 0 ? window.innerWidth : screen.width;
if (width < 768) {
  openNav.value = false;
}

const baseNavigationData = getMainNavigationElements();

const navigationData = computed(() => {
  const additionalNavElements: MainNavElement[] = [];

  if (featureStore.hasFeature('order_management')) {
    additionalNavElements.push({
      label: translator.get('nav.orders'),
      icon: IconBox,
      to: { path: '/dashboard/orders' },
      active: false,
    });
  }

  const currentCampaignId = featureStore.features.poolmirror_campaigns?.active_campaign_id;
  if (featureStore.hasFeature('poolmirror_campaigns') && currentCampaignId) {
    additionalNavElements.push({
      label: translator.get('nav.active_poolmirror_campaign'),
      icon: IconFileCertificate,
      to: { path: `/dashboard/poolmirror/${currentCampaignId}/detail` },
      active: false,
    });
  }

  if (featureStore.hasFeature('promptsuite')) {
    additionalNavElements.push({
      label: translator.get('nav.promptsuite'),
      icon: IconBrain,
      to: { path: `/dashboard/promptsuite/apps` },
      active: false,
    });
  }

  if (featureStore.hasFeature('cvanalyzer')) {
    additionalNavElements.push({
      label: translator.get('nav.cvanalyzer'),
      icon: IconBrain,
      to: { path: `/dashboard/cvanlayzer` },
      active: false,
    });
  }

  return [...baseNavigationData, ...additionalNavElements];
});

const logoutUser = () => {
  destroySession();
  // the router will reload to login automatically
  location.reload();
};
const isActiveClass = (path: string, to: MainNavElement['to'], isExact: boolean) => {
  if (!to) return '';
  if (to.exact) return isExact ? 'active' : '';
  return to && path.includes(to.path || '') ? 'active' : '';
};
</script>

<style scoped>
.page-wrapper {
  height: 100vh;
}
.navbar-brand-image {
  transition: transform 0.2s ease-in-out;
}
.navbar-lead {
  background-color: #1a191c;
}
.navbar-lead-main {
  padding: 40px 0px;
}
.navbar-lead-main .nav-item {
  padding: 10px 5px;
  margin: 2px 20px;
  /* background-color: rgba(255, 255, 255, 0.02); */
  border-radius: 5px;
  font-family: 'Inter', sans-serif;
}
.navbar-lead-main .dropdown-menu .dropdown-item {
  background-color: transparent !important;
}
.navbar-lead-main .nav-item.active {
  background-color: rgba(255, 255, 255, 0.05);
  border-bottom: rgba(255, 255, 255, 0);
}
.navbar-lead-main .nav-item .nav-link {
  font-size: 16px;
}

.navbar-lead.navbar-apptype-hriq {
  background-color: rgb(128, 128, 28);
}

.main-logo {
  max-width: 32px;
  max-height: 32px;
}

.navbar-lead .form-floating > .form-select {
  background-color: rgba(0, 0, 0, 0.1) !important;
  border-color: rgba(255, 255, 255, 0.1) !important;
}

@media (min-width: 768px) {
  .navbar-expand-md.navbar-vertical ~ .navbar.lead-navbar,
  .navbar-expand-md.navbar-vertical ~ .lead-wrapper {
    margin-left: 20rem !important;
  }

  .navbar-vertical.navbar-expand-md.navbar.lead-navbar {
    width: 20rem !important;
  }

  .navbar-lead-main .nav-item {
    margin: 1px 20px;
    padding: 6px 5px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.05);
  }
}
.text-truncate {
  max-width: 180px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.btn-icon-menu {
  padding: 0%;
}
.btn-icon-menu:hover {
  color: white;
  background-color: transparent;
  border-color: transparent;
  text-decoration: none;
}
</style>
