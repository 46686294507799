<template>
  <DropDown :align="'right'">
    <template #trigger="{ toggleDropdown }">
      <a href="#" class="btn btn-right-addon" @click.prevent="toggleDropdown()">
        <span>
          <component :is="sortDirectionIcon" :size="15"></component>
          <strong class="ms-1" v-if="currentSortLabel">{{ currentSortLabel }}</strong>
        </span>
      </a>
    </template>
    <template #dropdown>
      <label v-for="option in sortOptions" :key="option.key" class="dropdown-item">
        <input v-model="internalValue.key" class="form-check-input m-0 me-2" type="radio" :value="option.key" />
        {{ option.label }}
      </label>
      <div class="dropdown-divider"></div>
      <div class="p-3">
        <label class="form-label">{{ $t('sort.direction') }}</label>
        <div class="btn-group w-100">
          <button
            type="button"
            class="btn"
            :class="internalValue.direction === 'asc' ? 'btn-dark' : ''"
            @click.prevent="internalValue.direction = 'asc'"
          >
            A-Z <IconSortAscending :size="17" class="ms-2"></IconSortAscending>
          </button>
          <button
            type="button"
            class="btn"
            :class="internalValue.direction === 'desc' ? 'btn-dark' : ''"
            @click.prevent="internalValue.direction = 'desc'"
          >
            Z-A <IconSortDescending :size="17" class="ms-2"></IconSortDescending>
          </button>
        </div>
      </div>
    </template>
  </DropDown>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { DropDown } from '@prospective/lithium';
import { IconSortAscending, IconSortDescending } from '@tabler/icons-vue';

export interface SortState {
  key: string | undefined;
  direction: 'asc' | 'desc';
}

export interface SortOption {
  key: string;
  label: string;
}

// Props
export interface Props {
  dropDownWidth?: number;
  modelValue: SortState;
  sortOptions: SortOption[];
}

// declare the defaults
const props = withDefaults(defineProps<Props>(), {
  dropDownWidth: 300,
});

const emit = defineEmits<{
  (event: 'update:modelValue', value: SortState): void;
}>();

let internalValue = computed({
  get() {
    return props.modelValue ?? { key: undefined, direction: 'asc' };
  },
  set(value) {
    emit('update:modelValue', value);
  },
});

const sortDirectionIcon = computed(() => {
  return internalValue.value.direction === 'asc' ? IconSortAscending : IconSortDescending;
});

const currentSortLabel = computed(() => {
  return props.sortOptions.find((option) => option.key === internalValue.value.key)?.label;
});
</script>

<style>
.btn.btn-right-addon {
  border: none !important;
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}
</style>
