<template>
  <HeaderNavbar
    :title="order?.title || 'Your order'"
    :breadcrumbs="listBreadcrumbs"
    :no-border="true"
    :no-bottom-margin="true"
  >
    <template #left-side>
      <div class="d-flex align-items-center gap-3">
        <h4 class="text-muted m-0">{{ order?.provider_job_id }}</h4>
        <StatusIndicator :status="order?.lead_order_status" />
      </div>
    </template>
    <template #right-side v-if="featureStore.featureOrderManagment">
      <div class="d-flex align-items-center gap-2">
        <button class="btn" @click.stop="duplicateOrder">
          <IconCopyPlus :size="20" :stroke-width="1.2" />
          <span class="ms-1">{{ $t('btn.duplicate') }}</span>
        </button>
        <button class="btn btn-icon btn-outline-danger" @click="deleteConfirmationDialog = true">
          <IconTrash :size="20" :stroke-width="1.2" />
        </button>
      </div>
    </template>
  </HeaderNavbar>
  <div class="container-lead white-background pb-4">
    <Tabs
      :modelValue="route.name"
      @update:modelValue="(pathName: string) => router.push({ name: pathName, params: { id: orderId } })"
      :tabs="orderTabs"
      class="nav-bordered mb-4 sticky-top bg-white"
    />
    <div>
      <router-view :orderId="orderId" :allowPreviews="allowPreviews" :order="order" />
    </div>
  </div>
  <LiConfirmationDialog
    v-model:dialogShow="deleteConfirmationDialog"
    confirmButtonLabel="Delete"
    @onContinue="deleteOrder"
  >
    <h1 class="mb-3">{{ $t('orders.details.delete_order.title') }}</h1>
    <p>{{ $t('orders.details.delete_order.description') }}</p>
  </LiConfirmationDialog>
</template>
<script setup lang="ts">
import { request } from '@/api/client';

import translator from '@/locale/translator';
import { useCustomerFeaturesStore } from '@/stores/customerFeatures';
import type { Order } from '@/types/types';
import { ConfirmationDialog as LiConfirmationDialog, Tabs } from '@prospective/lithium';
import { IconCopyPlus, IconTrash } from '@tabler/icons-vue';
import { computed, onMounted, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useToast } from 'vue-toastification';
import HeaderNavbar from '../header/HeaderNavbar.vue';
import StatusIndicator from '@/components/generic/StatusIndicator.vue';

const route = useRoute();
const router = useRouter();
const toast = useToast();

const orderId = route.params.id as string;
const featureStore = useCustomerFeaturesStore();

const order = ref<Order>();
const isLoading = ref(true);
const deleteConfirmationDialog = ref(false);

/* -------------------------------- COMPUTED -------------------------------- */
const listBreadcrumbs = computed(() => [
  { label: translator.get('nav.orders'), path: { name: 'orders.list' } },
  {
    label: order.value?.title || translator.get('orders.details.title'),
    path: { name: 'order.detail', params: { id: orderId } },
  },
]);
const allowPreviews = computed(() => Boolean(featureStore.featureOrderManagment?.allow_previews));
const orderTabs = computed(() => {
  const tabs = [
    {
      label: translator.get('orders.details.info'),
      value: 'order.detail.info',
      isLoading: isLoading.value,
      badge: undefined,
    },
    {
      label: translator.get('orders.details.packages'),
      value: 'order.detail.packages',
      isLoading: false,
      badge: undefined,
    },
    {
      label: translator.get('orders.details.support'),
      value: 'order.detail.support',
      isLoading: false,
      // badge: '2' || true, //if you want to show notification badge
    },
    {
      label: translator.get('orders.details.job_posting'),
      value: 'order.detail.job_posting',
      isLoading: false,
      // badge: '2' || true, //if you want to show notification badge
    },
  ];

  if (allowPreviews.value)
    tabs.splice(1, 0, {
      label: translator.get('orders.details.preview'),
      value: 'order.detail.preview',
      isLoading: false,
      badge: undefined,
    });

  return tabs;
});

onMounted(async () => {
  try {
    // If use has no feature permission, redirect to home
    if (!featureStore.featureOrderManagment) return router.replace({ name: 'home' });

    const fetchOrder = await request('GET', `/v1/account/order/${orderId}`, {
      resolve: 'company,company.logo_image,stats_cache,creator',
    });
    order.value = fetchOrder?.data;
    isLoading.value = false;
  } catch (error) {
    toast.error('Something went wrong. Please try again later');
  }
});

const duplicateOrder = () => {
  window.localStorage.setItem('orderId', orderId);
  router.push({ name: 'order.create' });
};
const deleteOrder = async () => {
  const response = await request('DELETE', `/v1/account/order/${orderId}`, {}, {});

  if (response?.success) {
    router.push({
      name: 'orders.list',
    });
  }
  toast.success('The order has been deleted successfully', { icon: 'ti ti-check' });
};
</script>
<style scoped>
.container-lead.white-background {
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: auto;
}
</style>
