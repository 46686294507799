export default {
  language: 'Sprooch',
  sign_out: 'Abmälde',
  sign_in: 'Amälde',
  email_address: 'E-Mail Adrässe',
  'login.title': 'Mit Dim {app}-Konto amälde',
  'login.remember_me': 'Agmäldet blibe',
  'login.password_placeholder': 'Dis Passwort',
  'nav.home': 'Dahei',
  'nav.orders': 'Ufträg',
  'nav.active_poolmirror_campaign': 'Portfolio Kampagne',
  'nav.cvanalyzer': 'Lebenslauf Compi',
  'home.see_all_orders': 'Alli Ufträg aaluege',
  'home.see_all_orders_heading': 'Lueg doch Dim Uftrag!',
  'home.see_all_orders_subheading': 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed',
  'home.create_order': 'Neua Uftrag',
  'home.create_order_heading': 'Hesch no kei Uftrag?',
  'home.create_order_subheading': 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed',
  'portfolio.crawled_jobs': 'Gecrawlti Stelle',
  'portfolio.campaign_slots': 'Kampagne Slots',
  'portfolio.slot_usage': 'Slot Uslastig',
  'portfolio.subscription': 'Abo',
  'portfolio.subscription_ends_in': 'Endet',
  'portfolio.available_jobs': 'Verfüegbari Stellenaazeige',
  'portfolio.show_all_slots': 'Alli Slots aaluege',
  'portfolio.job_search_placeholder': '"/" zum Stelle sueche',
  'search.generic_placeholder': 'Drück "/" zum sueche',
  'cvanalyzer.list.title': 'Verarbeiteti Läbenslouf',
  'is_loading.generic': 'Date werde glade...',
  'sort.direction': 'Sortierigsrichtig',
  'sort.field.created_at': 'Erstelligsdatum',
  'sort.field.relevance': 'Relevanz',
  'orders.list.sort.title': 'Uftragstitel',
  'orders.layout.list': 'Lischt',
  'orders.layout.grid': 'Gitter',
  'orders.list.title': 'Alle Ufträg',
  'orders.list.filter_all': 'Alle ufträg',
  'orders.list.filter_active': 'Activ ufträge',
  'orders.list.filter_past': 'Vergangene ufträge',
  'orders.list.filter_my': 'Meine ufträge',
  'orders.create_button': 'Uftrag erstelle',
  'orders.list.total_clicks': 'Klicks',
  'orders.list.impressions': 'Impressione',
  'orders.list.days': 'Täg',
  'orders.list.worldwide': 'Wältwiit',
  'orders.pagination.page': 'Siite',
  'orders.pagination.prev': 'Vorherig',
  'orders.pagination.next': 'Nächschti',
  'orders.filters.company_name': 'Unternehme',
  'orders.filters.creator': 'Creator',
  'orders.filters.location': 'Arbeitsort',
  'orders.filters.language': 'Sprooch',
  'orders.filters.reset_filters': 'Zruggsetze',
  'orders.filters.apply': 'Aawände',
  'common.of': 'vo',

  'order.status.to_be_confirmed': 'Warte uft Beschtätigung',
  'order.status.confirmed': 'Beschtätigt',
  'order.status.running': 'Aktiv',
  'order.status.finished': 'Abgschlosse',
};
