<template>
  <div>
    <HeaderNavbar
      :title="$t('settings.all_settings')"
      :breadcrumbs="[{ label: $t('nav.settings'), path: { name: 'settings' } }]"
    />
    <div class="container-lead">
      <div class="mt-4">
        <div class="responsive-padding row gap-4">
          <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 d-flex justify-content-start me-3">
            <RouterLink v-for="card in settingsCards" :key="card.title" :to="card.to" v-slot="{ navigate }" custom>
              <div class="card m-0 nav-card selectable-card me-3" @click="navigate">
                <div class="card-body">
                  <div class="d-flex flex-column justify-content-center align-items-center">
                    <component :is="card.icon" class="nav-icon"></component>
                    <h3 class="pt-1 text-nowrap fw-bold">{{ card.title }}</h3>
                    <p class="text-muted m-0">{{ card.subtitle }}</p>
                  </div>
                </div>
              </div>
            </RouterLink>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { PageHeader as LiPageHeader } from '@prospective/lithium';
import HeaderNavbar from '../header/HeaderNavbar.vue';
import { IconUsers, IconStack2 } from '@tabler/icons-vue';
import translator from '@/locale/translator';

/* --------------------------------- STATE -------------------------------- */

const settingsCards = [
  {
    title: translator.get('settings.accounts.title'),
    subtitle: translator.get('settings.accounts.description'),
    icon: IconUsers,
    to: { name: 'settings.accounts' },
  },
  {
    title: translator.get('settings.companies.title'),
    subtitle: translator.get('settings.companies.description'),
    icon: IconStack2,
    to: { name: 'settings.companies' },
  },
];
</script>

<style lang="scss" scoped>
.nav-card {
  text-align: center;
  padding: 2rem 0;

  .nav-icon {
    width: 80px;
    height: 80px;
    stroke-width: 1;
    // color: var(--tblr-blue);
    color: #333;
  }
}
</style>
