import { createApp } from 'vue';
import { createPinia } from 'pinia';

import * as Sentry from '@sentry/vue';

import App from './App.vue';
import router from './router/router';

import '@prospective/lithium/lithium.css';
import '@/assets/lead.scss';

import VToast, { TYPE as VTType } from 'vue-toastification';

import vSelect from 'vue-select';
import 'vue-select/dist/vue-select.css';

import { Dropdown, VTooltip } from 'floating-vue';
import 'floating-vue/dist/style.css';
import FloatingVue from 'floating-vue';

import { authorizeClient } from './api/client';

import translator from './locale/translator.js';
import { TranslatorPlugin } from '@prospective/lithium';

/**
 * Setting the locales
 */
import { enUS } from 'date-fns/locale';
import { de } from 'date-fns/locale';

const app = createApp(App);
const pinia = createPinia();

Sentry.init({
  app,
  dsn: 'https://822f2198c2eb508c77428d915f8b58d2@o927288.ingest.sentry.io/4506592492781568',
  integrations: [
    new Sentry.BrowserTracing({
      tracePropagationTargets: ['localhost'],
    }),
    new Sentry.Replay({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  tracesSampleRate: 1.0,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

authorizeClient().then(() => {
  /**
   * Defaults
   */
  window.__pjas_date_locale = {
    en_US: enUS,
    de_CH: de,
  };
  window.__pjas_locale = 'en_US';

  app.use(VToast, {
    timeout: 7000,
    transition: 'Vue-Toastification__bounce',
    position: 'top-center',
    toastDefaults: {
      // ToastOptions object for each type of toast
      [VTType.ERROR]: {
        icon: 'ti ti-alert-octagon',
      },
      [VTType.WARNING]: {
        icon: 'ti ti-alert-triangle',
      },
      [VTType.SUCCESS]: {
        icon: 'ti ti-check',
      },
    },
  });
  app.component('VSelect', vSelect);

  app.component('VDropdown', Dropdown);
  app.directive('tooltip', VTooltip);
  FloatingVue.options.themes.tooltip.popperHideTriggers = ['hover'];

  app.use(pinia);
  app.use(router);
  app.use(TranslatorPlugin, { translator });

  app.mount('#app');
});
