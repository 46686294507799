<template>
  <div v-if="featureStore.featurePoolMirrorCampaign">
    <HeaderNavbar title="Profile Campaign" :breadcrumbs="listBreadcrumbs">
      <template #right-side>
        <HeaderNavbarSearch
          v-model="searchQuery"
          @focus="beginSearch"
          :placeholder="$t('portfolio.job_search_placeholder')"
        />
      </template>
    </HeaderNavbar>
    <div class="container-lead w-25" v-if="pageIsLoading">
      <div class="text-center mt-5">
        <div class="mb-3">
          <img :src="defaultLogoPath" height="200" alt="" />
        </div>
        <div class="text-muted mb-3">{{ $t('is_loading.generic') }}</div>
        <div class="progress progress-sm">
          <div class="progress-bar progress-bar-indeterminate"></div>
        </div>
      </div>
    </div>
    <div class="container-lead" v-else>
      <li-page-header
        :title="campaign.name ?? '...'"
        :subtitle="$t('nav.active_poolmirror_campaign')"
        :has-filters="false"
      >
      </li-page-header>

      <div class="row row-deck row-cards mb-4 mt-2">
        <div class="col-sm-6 col-lg-3">
          <div class="card animate__animated animate__fadeInDown animate__faster" style="animation-delay: 0s">
            <div class="card-body">
              <div class="d-flex align-items-center mb-1">
                <div class="subheader">Status</div>
              </div>
              <div>
                <span class="status" :class="statusColorClass">
                  <span class="status-dot status-dot-animated"></span>
                  {{ statusString }}
                </span>
              </div>
              <div class="mt-2">
                <div class="text-muted">
                  Publisher Backend: <strong class="text-uppercase">{{ campaign.publisher_backend }}</strong>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-6 col-lg-3">
          <div class="card animate__animated animate__fadeInDown animate__faster" style="animation-delay: 0.25s">
            <div class="card-body">
              <div>
                <div class="subheader">{{ $t('portfolio.slot_usage') }}</div>
              </div>
              <div class="h1 mb-3">{{ usedSlots }} <span class="text-muted">of</span> {{ campaign.max_slots }}</div>
              <div class="progress progress-sm">
                <div
                  class="progress-bar bg-primary"
                  :style="{ width: `${(usedSlots / campaign.max_slots) * 100}%` }"
                ></div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-6 col-lg-3">
          <div class="card animate__animated animate__fadeInDown animate__faster" style="animation-delay: 0.5s">
            <div class="card-body">
              <div class="d-flex align-items-center">
                <div class="subheader">{{ $t('portfolio.crawled_jobs') }}</div>
              </div>
              <div class="h1 mb-1">{{ crawledJobCount }}</div>
              <div class="d-flex">
                <div class="text-muted">
                  Last crawled <strong>{{ lastSyncDate }}</strong>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-6 col-lg-3">
          <div class="card animate__animated animate__fadeInDown animate__faster" style="animation-delay: 0.75s">
            <div class="card-body">
              <div class="d-flex align-items-center">
                <div class="subheader">{{ $t('portfolio.subscription') }}</div>
              </div>
              <div class="h1 mb-3">
                <span class="text-muted">{{ $t('portfolio.subscription_ends_in') }}</span> {{ remainingDays }}
              </div>
              <div class="progress progress-sm">
                <div class="progress-bar bg-primary" :style="{ width: `${100 - remainingDaysPercentage}%` }"></div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="animate__animated animate__fadeInUp" style="animation-delay: 0.1s">
        <li-page-header
          class="h2"
          :title="$t('portfolio.campaign_slots')"
          :subtitle="$t('nav.active_poolmirror_campaign') + ' ' + (campaign.name ?? '...')"
          :has-filters="false"
        >
        </li-page-header>

        <div class="row mt-3 lead-available-slots-container" :class="{ expanded: showSlots }">
          <div class="col-xl-4 col-lg-6 mb-3" v-for="(slot, index) in availableSlotObjects" :key="index">
            <PoolmirrorSlot
              :campaign-slot="slot"
              :publication="publicationMap[slot.crawled_job?.id]"
              @did-change-priority="fetchData"
            ></PoolmirrorSlot>
          </div>
        </div>

        <div class="position-relative" v-if="!showSlots">
          <div class="position-absolute w-100 text-center" style="margin-top: -50px">
            <button class="btn btn-white lead-show-more-slots-btn" @click="showAllSlots">
              {{ $t('portfolio.show_all_slots') }} <IconArrowDown class="ms-2" :size="16"></IconArrowDown>
            </button>
          </div>
        </div>
      </div>

      <div class="animate__animated animate__fadeInUp" style="animation-delay: 0.25s">
        <div class="animate__animated animate__fadeInUp animate__faster"></div>
        <li-page-header
          class="h2 mt-4"
          id="portfolio-available-jobs-header"
          :title="$t('portfolio.available_jobs')"
          :subtitle="$t('nav.active_poolmirror_campaign') + ' ' + (campaign.name ?? '...')"
          :has-filters="false"
        >
          <button class="btn btn-white" @click="showFilters = !showFilters">
            <IconFilter class="me-2"></IconFilter>
            <!-- <span class="badge badge-sm bg-red">6</span> -->
          </button>
        </li-page-header>

        <div v-if="showFilters" class="mt-4">
          <div class="row">
            <div class="col-sm-6 col-lg-3 mb-3">
              <FilterButtonAutocomplete
                :label="'Arbeitsort'"
                :search="workplaceFilterOptions"
                v-model="filters.workplace_location"
                :reset-value="null"
                :allow-reset="true"
              ></FilterButtonAutocomplete>
            </div>
            <div class="col-sm-6 col-lg-3 mb-3">
              <FilterButtonSelect
                :label="'Sprache'"
                v-model="filters.language"
                :options="[
                  { label: 'Deutsch', value: 'de' },
                  { label: 'Englisch', value: 'en' },
                  { label: 'Französisch', value: 'fr' },
                  { label: 'Italienisch', value: 'it' },
                ]"
                :reset-value="null"
                :allow-reset="true"
              ></FilterButtonSelect>
            </div>
          </div>
        </div>

        <div class="row mt-3" :style="{ minHeight: '700px' }">
          <div class="col-md-12" :style="{ zIndex: 2 }">
            <div v-for="job in filteredJobs.slice(0, shownResults)" :key="job.id">
              <PoolmirrorListRow
                :job="job"
                :publication="publicationMap[job?.id]"
                :campaign-id="campaign.id"
                :can-activate="remainingSlots > 0"
                @did-change-priority="fetchData"
              ></PoolmirrorListRow>
            </div>
            <div v-if="filteredJobs.length === 0">
              <div class="empty">
                <div class="empty-icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="icon"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    stroke-width="2"
                    stroke="currentColor"
                    fill="none"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <path d="M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0" />
                    <path d="M9 10l.01 0" />
                    <path d="M15 10l.01 0" />
                    <path d="M9.5 15.25a3.5 3.5 0 0 1 5 0" />
                  </svg>
                </div>
                <p class="empty-title">No results found</p>
                <p class="empty-subtitle text-muted">
                  Try adjusting your search or filter to find what you're looking for.
                </p>
              </div>
            </div>

            <!-- show more button -->
            <div v-if="shownResults < filteredJobs.length" class="text-center p-4">
              <button class="btn btn-pill-secondary" @click="shownResults += 25">
                Show more <i class="ti ti-arrow-down"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, computed, reactive } from 'vue';
import type { Ref } from 'vue';
import { useRoute } from 'vue-router';
import { formatDistance, parseISO } from 'date-fns';
import { PageHeader as LiPageHeader, FilterButtonAutocomplete, FilterButtonSelect } from '@prospective/lithium';
import { fetchPoolmirrorCrawledJobs, fetchPoolmirrorPublications } from '@/api/poolmirror';
import { getReferenceNumForJob } from '@/func/poolmirror/refnum';

import PoolmirrorListRow from './PoolmirrorListRow.vue';
import PoolmirrorSlot from './PoolmirrorSlot.vue';

import Fuse from 'fuse.js';
import type { ResponseData } from '@/api/client';
import { IconArrowDown, IconFilter } from '@tabler/icons-vue';

import { useCustomerFeaturesStore } from '@/stores/customerFeatures';
import HeaderNavbar from '../header/HeaderNavbar.vue';
import HeaderNavbarSearch from '../header/HeaderNavbarSearch.vue';
import { defaultLogoPath } from '@/config/app';

export interface Slot {
  id: number;
  name: string;
  campaign_id: number;
  crawled_job: ResponseData | undefined;
}

interface CrawledJobFilters {
  priority: number | null;
  workplace_location: string | null;
  language: string | null;
}

const route = useRoute();

const featureStore = useCustomerFeaturesStore();

const pageIsLoading = ref(true);
const campaignId = route?.params?.id as string;
const campaign: Ref<ResponseData> = ref({});
const jobs: Ref<ResponseData[]> = ref([]);
const publicationMap = ref<{ [key: string]: ResponseData }>({});
const searchQuery = ref('');
const shownResults = ref(25);
const showSlots = ref(false);
const showFilters = ref(false);
const searchInput = ref<HTMLInputElement | null>(null);
const filters = reactive<CrawledJobFilters>({
  priority: null,
  workplace_location: null,
  language: null,
});

function fetchData() {
  fetchPoolmirrorCrawledJobs(parseInt(campaignId)).then((res) => {
    pageIsLoading.value = false;
    campaign.value = res.campaign;
    jobs.value = res.data;

    // preprocess crawled jobs, forward the proper reference number
    for (const job of jobs.value) {
      job.reference_num = getReferenceNumForJob(job, featureStore.featurePoolMirrorCampaign);
    }
  });

  fetchPublicationData();
}

function fetchPublicationData(): void {
  // we only fetch the active publications and map them
  // by their jce_job_id so we can easily access them later from the crawled
  // jobs. This is needed to be able to show the publication status and map them to
  // a PJAS order.
  fetchPoolmirrorPublications(parseInt(campaignId), { active: 1 }).then((res) => {
    publicationMap.value = {};
    for (const publication of res.data) {
      publicationMap.value[publication.jce_job_id] = publication;
    }
  });
}

fetchData();

const listBreadcrumbs = computed(() => [
  { label: 'Campaign', path: { name: 'poolmirror.detail', params: { id: campaignId } } },
]);
const crawledJobCount = computed(() => {
  return jobs.value.length;
});

const usedSlots = computed(() => {
  return jobs.value.filter((job) => job.priority === 1).length;
});

const lastSyncDate = computed(() => {
  if (!campaign.value.last_sync) {
    return 'never';
  }
  return formatDistance(parseISO(campaign.value.last_sync), new Date(), {
    addSuffix: true,
  });
});

const remainingDays = computed(() => {
  if (!campaign.value.enddate) {
    return 'never';
  }
  return formatDistance(parseISO(campaign.value.enddate), new Date(), {
    addSuffix: true,
  });
});

const remainingSlots = computed(() => {
  return campaign.value.max_slots - usedSlots.value;
});

const remainingDaysPercentage = computed(() => {
  const startdate = parseISO(campaign.value.startdate);
  const enddate = parseISO(campaign.value.enddate);
  const now = new Date();

  const totalDays = Math.round((enddate.getTime() - startdate.getTime()) / (1000 * 3600 * 24));
  const remainingDays = Math.round((enddate.getTime() - now.getTime()) / (1000 * 3600 * 24));

  return Math.round((remainingDays / totalDays) * 100);
});

const statusString = computed(() => {
  const status = campaign.value.status;
  switch (status) {
    case 0:
      return 'Waiting';
    case 1:
      return 'Everything OK';
    case -1:
      return 'Critical Issue';
    case -2:
      return 'Logical Issue';
    default:
      return 'Unknown';
  }
});

const statusColorClass = computed(() => {
  const status = campaign.value.status;
  switch (status) {
    case 0:
      return 'status-blue';
    case 1:
      return 'status-green';
    case -1:
      return 'status-red';
    case -2:
      return 'status-yellow';
    default:
      return 'status-blue';
  }
});

const fuseJobs = computed(() => {
  return new Fuse(jobs.value, {
    includeMatches: false,
    minMatchCharLength: 2,
    threshold: 0.3,
    keys: ['title', 'provider_key', 'reference_num', 'workplace_location'],
  });
});

const filteredJobs = computed<ResponseData[]>(() => {
  let referencedResults: ResponseData[] = [];

  if (searchQuery.value === '') {
    referencedResults.push(...jobs.value);
  } else {
    fuseJobs.value.search(searchQuery.value).forEach((result) => {
      referencedResults.push(jobs.value[result.refIndex]);
    });
  }

  // filter the referenced results by the filters
  if (filters.priority !== null) {
    referencedResults = referencedResults.filter((job) => job.priority === filters.priority);
  }

  if (filters.workplace_location !== null) {
    referencedResults = referencedResults.filter((job) => job.workplace_location === filters.workplace_location);
  }

  if (filters.language !== null) {
    referencedResults = referencedResults.filter((job) => job.language === filters.language);
  }

  return referencedResults;
});

const availableSlotObjects = computed(() => {
  const slots: Slot[] = [];
  const prioritisedJobs = jobs.value.filter((job) => job.priority === 1);

  for (let i = 1; i <= campaign.value.max_slots; i++) {
    slots.push({
      id: i,
      name: `Slot ${i}`,
      campaign_id: campaign.value.id,
      crawled_job: prioritisedJobs[i - 1] ?? undefined,
    });
  }
  return slots;
});

function showAllSlots() {
  showSlots.value = true;
}

function workplaceFilterOptions(query: string): Promise<string[]> {
  return new Promise((resolve) => {
    const options = jobs.value
      .map((job) => job.workplace_location)
      .filter((option) => (option ?? '')?.toLowerCase().includes(query?.toLowerCase()));

    resolve(options);
  });
}

function beginSearch() {
  searchInput.value?.focus();
  // scroll to #portfolio-available-jobs-header with a 100px offset
  const header = document.querySelector('#portfolio-available-jobs-header');
  if (header) {
    window.scrollTo({
      top: header.getBoundingClientRect().top + window.pageYOffset - 120,
      behavior: 'smooth',
    });
  }
}
</script>

<style>
.lead-show-more-slots-btn {
  box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.1) !important;
}

.lead-available-slots-container {
  max-height: 120px;
  overflow-y: hidden;
  position: relative;
  transition: max-height 0.8s ease;
}

.lead-available-slots-container.expanded {
  max-height: none;
}

.lead-available-slots-container::after {
  background: linear-gradient(rgba(255, 255, 255, 0), rgba(241, 245, 249, 1) 100%);
  content: '';
  display: block;
  height: 50px;
  bottom: 0;
  left: 0;
  position: absolute;
  width: 100%;
  opacity: 1;
  transition: opacity 0.8s ease;
}

.lead-available-slots-container.expanded::after {
  opacity: 0;
}
</style>
